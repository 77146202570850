import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import HeaderBanner from '../ui/HeaderBanner';


interface SubChild {
  text: string;
}

interface child {
  children: SubChild[]
}




interface Content {
  Explanation: child[],
  Speaker: string,
  Title: string
}
interface myDATA {
  Workshops: Content[]
}
const Workshops = () => {
  const [data, setData] = useState<myDATA>()

  useEffect(() => {
    client.fetch('*[_type == "Programs"]')
      .then((result) => {
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Workshops' />

      <div className="p-4 my-4 grid gap-5 text-left space-y-2 mb-24">
        {
          data?.Workshops && data.Workshops.map((item, index) => {
            return <>

              <h1 className='text-red-500 text-3xl font-semibold'>Workshop {index + 1} : {item.Title}</h1>
              <div className='border rounded-lg border-stale-200 p-4 mx-6'>
                <h2 className='text-xl'><span className='font-bold'>Speaker - </span> {item.Speaker}</h2>
                <p>
                  {item.Explanation[0].children[0].text}
                </p>
              </div>

            </>
          })
        }
      </div>
    </div>
  )
}

export default Workshops