import React, { useEffect, useState } from 'react'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'
import { Typography } from '@material-tailwind/react';



const TopCitedPapers = () => {
  const [data, setData] = useState<any>()

  useEffect(() => {
    client.fetch('*[_type == "TopCitedPapers"]')
      .then((result) => {
  
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])

  const TABLE_HEAD = ["Year", "Citations", "Authors", "Paper", "Conference", "Country"];


  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title="Top Cited Papers" />

      <div>
        <h1 className='text-xl font-bold text-blue-500'>SSD in Scopus</h1>
        <p> {data?.SSDinScopus[0].children.map((item: any, index: any) => {
          return (
            <span>
              <a className={data?.SSDinScopus[0].markDefs.filter((data: any) => data._key == item.marks[0])[0]?.href && "text-blue-500"} href={data?.SSDinScopus[0].markDefs.filter((data: any) => data._key == item.marks[0])[0]?.href}>
                {item.text}
              </a>
            </span>
          )
        })}</p>
      </div>

      <div className="p-4 text-left space-y-12">
        <div>
          <h1 className='text-xl font-bold text-blue-500'>Best Cited Papers Per Year</h1>
          <p className='p-2'>
            <table className="w-full max-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody >
                {data?.BestCitedPapersPerYear.map(({ Authors, Citations, Country, Paper, Year }: any, index: any) => {
                  const isLast = index === data?.BestCitedPapersPerYear - 1;
                  const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                  return (
                    <tr key={Year}>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Year}
                        </Typography>
                      </td>
                      <td className={`${classes} bg-blue-gray-50/50`}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Citations}
                        </Typography>
                      </td>
                      <td className={classes} >
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Authors}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Paper}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Citations}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Country}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* {items.children[0].text} {items.children[1] && <a className="text-blue-300" href={items.children[1].text}>{items.children[1].text}</a>} */}
          </p>
        </div>
        <div>
          <h1 className='text-xl font-bold text-blue-500'>Top 5 Best Cited Papers in the SSD History</h1>
          <p className='p-2'>
            <table className="w-full max-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody >
                {data?.TopFive.map(({ Authors, Citations, Country, Paper, Year }: any, index: any) => {
                  const isLast = index === data?.TopFive - 1;
                  const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                  return (
                    <tr key={Year}>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Year}
                        </Typography>
                      </td>
                      <td className={`${classes} bg-blue-gray-50/50`}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Citations}
                        </Typography>
                      </td>
                      <td className={classes} >
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Authors}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Paper}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Citations}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {Country}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* {items.children[0].text} {items.children[1] && <a className="text-blue-300" href={items.children[1].text}>{items.children[1].text}</a>} */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopCitedPapers;


