import React, { useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

interface speakerContent {
  Role: string;
  AbstractTitle: string;
  Abstract: Array<{
    children: Array<{
      text: string;
    }>;
  }>;
  image: {
    asset: {
      _ref: string;
    };
  };
  Biography: BioType[];
  linkedinUrl: string;
  name: string;
  profileUrl: string;
  university: string;
}

interface BioType {
  children: Array<{
    text: string;
  }>;
}

interface BioModalProps {
  open: boolean;
  handleOpen: () => void;
  bioData: speakerContent;
}

const BioModal = (props: BioModalProps) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.open && dialogRef.current) {
      // Scroll to the top when the dialog opens
      dialogRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.open]);

  return (
    <Dialog
      style={{ width: "1000px" }}
      open={props.open}
      handler={props.handleOpen}
      className='p-2 overflow-y-auto max-h-96 w-3/5'
      ref={dialogRef}
    >
      <DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg'>
        {props?.bioData?.AbstractTitle && (
          <h1 className='font-semibold text-red-500'>
            {props.bioData.AbstractTitle}
          </h1>
        )}
      </DialogHeader>
      <DialogBody>
        {props?.bioData?.Abstract?.length > 0 && (
          <div className=''>
            {props?.bioData?.Abstract.map((e, i) => {
              return (
                <div className='p-2' key={i}>
                  <p className=''>{e.children[0].text}</p>
                </div>
              );
            })}
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button variant='gradient' color='green' onClick={props.handleOpen}>
          <span>Okay</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default BioModal;
