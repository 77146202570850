import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Avatar,
    IconButton,
    Typography,
    Card,
  } from "@material-tailwind/react";


import PreviousSSD from '../../sanityInterfaces/PreviousSSD'
import HeaderBanner from '../ui/HeaderBanner';
import { Link } from 'react-router-dom';
import TechnicalProgModal from '../utils/TechnicalProgModal';
import SpeakersModal from '../utils/SpeakersModal';
import WorkshopsModal from '../utils/WorkshopsModal';
import ChairsModal from '../utils/ChairsModal';
import SPecialSessionsModal from '../utils/SPecialSessionsModal';
const PreviousSSDLayout = (props:{versionData:PreviousSSD}) => {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [openTech, setOpenTech] = useState(false);
    const [openSpeakers, setOpenSpeakers] = useState(false);
    const [openChairs, setOpenChairs] = useState(false);
    const [openWorkshop, setOpenWorkshop] = useState(false);
    const [openSpecial, setOpenSpecial] = useState(false);

    const handleIndex = (n:number) =>{
      if (currentImageIndex + n > props?.versionData?.PHOTOGallery?.length-1 ) {
        setCurrentImageIndex(0);
        setCurrentImage(`https://cdn.sanity.io/images/79vgbaej/production/${props?.versionData?.PHOTOGallery[0]?.Title?.asset?._ref?.slice(6, -4) + '.' + props?.versionData?.PHOTOGallery[0]?.Title?.asset?._ref?.slice(-3)}`)
      } else if (currentImageIndex + n < 0 ){
        
        setCurrentImageIndex(props?.versionData?.PHOTOGallery.length - 1);
                setCurrentImage(`https://cdn.sanity.io/images/79vgbaej/production/${props?.versionData?.PHOTOGallery[props?.versionData?.PHOTOGallery.length - 1]?.Title?.asset?._ref?.slice(6, -4) + '.' + props?.versionData?.PHOTOGallery[props?.versionData?.PHOTOGallery.length - 1]?.Title?.asset?._ref?.slice(-3)}`)
        
      }
      else{
        setCurrentImageIndex(currentImageIndex+n);
        setCurrentImage(`https://cdn.sanity.io/images/79vgbaej/production/${props?.versionData?.PHOTOGallery[currentImageIndex+n]?.Title?.asset?._ref?.slice(6, -4) + '.' + props?.versionData?.PHOTOGallery[currentImageIndex+n]?.Title?.asset?._ref?.slice(-3)}`)
        

      }
    }
const handleClick=(src:string,index:number)=>{
        setCurrentImageIndex(index);
        setCurrentImage(src);
        handleOpen();
    }
    const handleOpen = ()=>{
        
        setOpen(!open);
    }
    useEffect(() => {},[currentImage])
   
const handleOpenTech = ()=>{
        setOpenTech(!openTech);
    }
    const handleOpenChairs = ()=>{
      setOpenChairs(!openChairs);
  }
    const handleOpenSpeakers = ()=>{
      setOpenSpeakers(!openSpeakers);
  }
  const handleOpenWorkshop = ()=>{
    setOpenWorkshop(!openWorkshop);
}
const handleOpenSpecial = ()=>{
  setOpenSpecial(!openSpecial);
}
    useEffect(() => {

      },[openTech,openSpeakers,openWorkshop,openSpecial,openChairs])
   

  return (
    <>
    <HeaderBanner title='Previous version' />
        <div className=''>
            <div className='flex items-center space-x-8' >
               <h1 className='font-bold text-3xl text-red-500'  >
                    {props?.versionData?.SSDVersionYear}
                </h1> 
                <div>
                    <p className='text-xl'>{props?.versionData?.SSDEditionNumber} Edition
               </p>
               <p className='text-red-400 font-semibold text-xl'>{props?.versionData?.Date}-{props?.versionData?.Place}</p>
                     </div>
            </div>
            <div className='flex justify-between items-center space-x-3'>
                <p className='border-2 w-2/3 p-8 h-full'>
                    {props?.versionData?.Description}
                </p>
                
 <div className='grid float-left mr-6 p-1  rounded-lg border-2 border-red-200 gap-3'>

                <img className='border w-full rounded-lg object-cover object-center h-60  w-100 ' src={`https://cdn.sanity.io/images/79vgbaej/production/${props?.versionData?.SSDVersionImage?.asset?._ref.slice(6, -4) + '.' + props?.versionData?.SSDVersionImage?.asset?._ref.slice(-3)}`}/>
 </div>
            </div>
            <div className='my-4 grid gap-3 grid-cols-2'>
                
                
                
                    <button className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 font-semibold ' onClick={()=>handleOpenTech()}>Tenchical Program </button>
                    <button className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 font-semibold ' onClick={()=>handleOpenSpeakers()}>Speakers </button>
                    <button className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 font-semibold ' onClick={()=>handleOpenWorkshop()}>Workshops </button>
                    <button className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 font-semibold ' onClick={()=>handleOpenChairs()}>Chairs </button>
                    <button className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 font-semibold ' onClick={()=>handleOpenSpecial()}>Special Sessions </button>
                    
            </div>
            {
                props?.versionData?.PHOTOGallery && props?.versionData?.PHOTOGallery.length >0 && <div className='my-2 p-4'>
                <h1 className='font-bold text-2xl text-red-500'>
                    Photo Gallery
                </h1>
                <div className='border-2 p-4 flex flex-wrap'>
                {
                  props?.versionData?.PHOTOGallery.map((item,index)=>{
                    return <>

                    <img onClick={()=>{handleClick(`https://cdn.sanity.io/images/79vgbaej/production/${item?.Title?.asset?._ref?.slice(6, -4) + '.' + item?.Title?.asset?._ref?.slice(-3)}`,index)}} className='w-1/3 my-3 px-2 cursor-pointer object-contain' src={`https://cdn.sanity.io/images/79vgbaej/production/${item?.Title?.asset?._ref?.slice(6, -4) + '.' + item?.Title?.asset?._ref?.slice(-3)}`} alt="" />
                    </>
                  })  
                }
                </div>
            </div>
            }

        </div>
        <Dialog style={{background: "transparent"}} size="xl" className='imgModal' open={open} handler={handleOpen}>
  <DialogBody>
    <div className="relative">
      <button 
        className="absolute top-1/2 left-4 -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition duration-300 hover:bg-opacity-75"
        onClick={()=>handleIndex(-1)}
      >
        
        <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/previous.png" alt="previous"/>
      
      </button>
      <img
        alt="nature"
        className="h-full w-full rounded-lg object-cover object-center"
        src={currentImage}
      />
      <button 
        className="absolute top-1/2 right-4 -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition duration-300 hover:bg-opacity-75"
        onClick={()=>handleIndex(1)}
      >
        {/* Next item icon or text */}
        <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/next.png" alt="next"/>
        </button>
    </div>
  </DialogBody>
</Dialog>

      <TechnicalProgModal open={openTech} data={props?.versionData} handleOpen={handleOpenTech} />
      <SpeakersModal open={openSpeakers} data={props?.versionData} handleOpen={handleOpenSpeakers} />
      <WorkshopsModal open={openWorkshop} handleOpen={handleOpenWorkshop} data={props?.versionData} />
      <ChairsModal open={openChairs} handleOpen={handleOpenChairs} data={props?.versionData} />
      <SPecialSessionsModal open={openSpecial} handleOpen={handleOpenSpecial} data={props?.versionData} />
      </>
  )
}

export default PreviousSSDLayout