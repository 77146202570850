import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import HeaderBanner from '../ui/HeaderBanner';
interface Datacontent {
  BAIName: string,
  BAIBank: string,
  BAIBankBankagency: string,
  BAIBankIBAN: string,
  BAIBankNumber: string,
  BAIBankBICSwift: string,
  BAIBankTransferpurpose: string,
  RegistrationFormForTunisianAttendees:string,
  RegistrationForm:string
}
const Registration = () => {
  const [data, setData] = useState<Datacontent>()

  useEffect(() => {
    client.fetch('*[_type == "AccomodationANDRegistration"]') 
      .then((result) => {
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [])

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 pb-24">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Registration' />
      <div className=" text-left grid gap-8 pt-12">
        <h1 className=" text-sm  font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">For all authors, please fulfill the registration form and send it to: <a className='text-blue-500' href='mailto:registration@ssd-conf.org'>registration@ssd-conf.org</a></h1>
        <div className='grid grid-cols-2 gap-2'>
          <div className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500 '><a href={`${data?.RegistrationFormForTunisianAttendees}`} >Registration form for local attendees </a></div>
          <div className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500'><a href={`${data?.RegistrationForm}`} >Registration form (Attendance conference)</a></div>
        </div>
        <div className='flex'><p className='text-black'>Please transfer the conference fee according to early registration to the following bank account. You can only be registered if you have paid your conference fee.</p></div>
        <h1 className='w-full grid justify-items-center text-xl font-semibold text-red-400' >Bank information</h1>
        <table className="rounded-t-lg m-5 w-full mx-auto bg-gray-200 text-gray-800">
          {data ?
            <>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">Name</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIName}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">Bank</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBank}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">Bank agency</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBankBankagency}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">IBAN</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBankIBAN}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">Account Number</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBankNumber}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">BIC / Swift</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBankBICSwift}</span></td>
              </tr>
              <tr>
                <td className="px-4 py-3 text-left border-b-2 border-gray-300">Transfer purpose</td>
                <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{data.BAIBankTransferpurpose}</span></td>
              </tr>
            </> : null}
        </table>

        <h1 className="text-md font-semibold text-red-300 my-4" data-aos="zoom-out">Attendees from low-income countries can request a fees reduction. For more details, contact: <a className='text-blue-500' href='mailto:registration@ssd-conf.org'>registration@ssd-conf.org</a> </h1>

      </div>
    </div>
  )
}

export default Registration