import React from 'react'
import Hero from '../hero'
import Features from '../features'
import FeaturesBlocks from '../features-blocks'
import Newsletter from '../newsletter'
import Testimonials from '../testimonials'

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <FeaturesBlocks />
      <Testimonials />
      <Newsletter />

    </>
  )
}

export default Home