import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react'
import PreviousSSD from '../../sanityInterfaces/PreviousSSD';
interface modalProps {
    open: boolean;
    handleOpen: () => void;
    data:PreviousSSD;
  }
const SPecialSessionsModal = (props:modalProps) => {
   
  return (
    <Dialog size='xl'  className='p-2 max-h-3/4 overflow-y-auto w-3/5' handler={props?.handleOpen}  open={props?.open} >
<DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg m-2'>
    <h1 className='text-red-500'>Special sessions </h1>
</DialogHeader>
<DialogBody>
<div className="p-4 my-4 grid gap-5 text-left space-y-2 mb-24">
        {
          props?.data?.SpecialSession && props?.data.SpecialSession?.map((item, index) => {
            return <>

              <h1 className='text-red-500 text-3xl font-semibold'>{item?.SpecialSessionTitle}</h1>
              <div className='border grid rounded-lg border-stale-200 p-4 mx-6'>
                {item?.SpecialSessionDescription?.length>0 && item?.SpecialSessionDescription?.map((e,i)=>{
                    return <div className='flex space-x-8' key={i}>
                   {i===item?.SpecialSessionDescription?.length-1? <>
                    {<p className='my-4'> {e?.children[0]?.text}</p>}
                   
                   </>:<>
                   {i === 0 ?<p className='text-xl font-semibold text-red-500'> {e?.children[0]?.text}</p>:<p> {e?.children[0]?.text}</p>}
                   {e?.children?.length>1 && <p className='font-semibold text-cyan-500'>{e?.children[1]?.text}</p>}
                   </> }
                    </div>
                })}
              </div>

            </>
          })
        }
      </div>

</DialogBody>
<DialogFooter>
<Button variant="gradient" color="green" onClick={props.handleOpen}>
            <span>Okay</span>
          </Button>
</DialogFooter>
    </Dialog>
  )
}

export default SPecialSessionsModal

