import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../client/Client";
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
interface OrganizersContent {
  Date: string;
  Information: string;
  Title: string;
  ReadMore: Array<{
    children:Array<{text:string}>;
  }>;
}
export default function Testimonials() {
  const [open, setOpen] = useState(false);
  const [moreDetails, setMoreDetails] = useState<OrganizersContent>({
    Date: "",
    Information: "",
    Title: "",
    ReadMore: []
  })
  const [data, setData] = useState<OrganizersContent[]>([]);
  useEffect(() => {
    client
      .fetch('*[_type == "RecentNews"]')
      .then((result) => {
        setData(result[0].RecentNews);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
const handleOpen = () =>{
  setOpen(!open)
}
const handleClick = (item:OrganizersContent) =>{
  setMoreDetails(item);
  handleOpen();
}
useEffect(() => {
  
}, [moreDetails])

  return (
    <section className="relative pt-16">
  <div className="grid place-content-center">
    <h1 className="text-3xl font-semibold text-red-400">Recent News</h1>
  </div>
  <div className="flex flex-wrap justify-center gap-6 lg:max-w-7xl mx-auto mt-3">
  {data &&
    data.map((e, i) => {
      return (
        <div className="w-80 h-80 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex items-center justify-center px-5 mb-3" key={i}>
          <a href="#"></a>
          {e && (
            <div className="p-5 text-center">
              <a href="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {e?.Title}
                </h5>
              </a>
              <div className="text-xs font-bold uppercase text-teal-700 mt-1 mb-2">
                {e?.Date}
              </div>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                {e?.Information}
              </p>
              <button onClick={()=>{handleClick(e)}} className=" cursor-pointer bg-red-400 py-2  px-6  rounded-3xl text-white hover:bg-red-600" >Read More</button>
            </div>
          )}
        </div>
      );
    })}
</div>

<Dialog handler={handleOpen} open={open}>
  <DialogHeader  className="p-4 grid place-content-start border-2 m-2 border-gray-500 rounded-xl">
<h1 className="font-semibold text-red-500" >{moreDetails?.Title}</h1>
<p className="font-semibold text-cyan-500 text-sm">{moreDetails?.Date}</p>
  </DialogHeader>
  <DialogBody>

{
  moreDetails?.ReadMore?.length >0 && <div>
    {moreDetails?.ReadMore?.map((e,i)=>{
      return <>{e?.children?.map((element,ind)=>{
        return <p className="font-semibold">{element?.text}</p>
      })}</>
    })}
  </div>
}
  </DialogBody>
    <DialogFooter>
  <Button className="bg-red-500 text-white" onClick={handleOpen}>
    Okay
  </Button>
    </DialogFooter>
</Dialog>
</section>


  );
}
