import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Tooltip } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import PreviousSSD from '../../sanityInterfaces/PreviousSSD';
import BioModal from './BioModal';
import '../styles/ModalsStyle.css'
interface modalProps {
    open: boolean;
    handleOpen: () => void;
    data:PreviousSSD;
  }
  interface BioType{
    children: Array<{
      text:string
    }>
  }
  interface speakerContent {
    Role: string,
  AbstractTitle:string,
  Abstract:Array<{children:Array<{
    text:string,
  }>}>,
    image: {
      asset: {
        _ref: string
      }
    },
    Biography:BioType[],
    name: string,
    profileUrl: string,
    university: string,
  
  }
const SpeakersModal = (props:modalProps) => {
    const [bioData, setBioData] = useState<speakerContent>({
        Role: "",
  AbstractTitle:"",
  Abstract:[],
    image: {
      asset: {
        _ref: ""
      }
    },
    Biography:[],
    name: "",
    profileUrl: "",
    university: "",
    })
    const [open, setOpen] = useState(false);
  const handleOpen =() => {
   
    setOpen(!open);
  
  }
    const handleCardClick =(item: speakerContent) => {
 
        setBioData(item);
      handleOpen()
    };
    useEffect(() => {
      
    }, [bioData])
    
  return (
    <Dialog size='xl' className=' p-2 max-h-3/4 overflow-y-auto w-3/5' handler={props?.handleOpen} open={props?.open} >
        <DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg m-2'>
        <h1 className='text-red-500' > Speakers {props?.data?.SSDVersionYear} </h1>
        </DialogHeader>
        <DialogBody>
        <div className="p-4 gap-3 text-left grid grid-cols-3">
        {
          props?.data?.SpeakerPage && props?.data?.SpeakerPage.map((item, index) => {
            return <div className=" bg-white rounded-lg shadow-md p-5">
              <div className='relative flex flex-col h-full p-6 bg-white rounded shadow-xl'>
              <Tooltip 
              
                  interactive={true}
                  content={
                <div  className=''>
                
                <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
              {  
                item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                    
                     
                                      return <p> - {e.children[0].text} </p>
                   
                   
                  }):<h1 className=''> There is no Bio for this person</h1>
              }
              </div>
              } className="modal-tooltip cursor-pointer max-h-44 max-w-xl overflow-y-auto" 
               >
                <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                </span>
              </Tooltip>
                {item.image.asset && <img className="w-32 h-32 rounded-full mx-auto " src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + "." + item.image.asset._ref.slice(-3)}`} alt="Profile picture" />}
                <a href={item.profileUrl} className="text-center text-2xl font-semibold mt-3">{item.name}</a>
                <p className="text-center text-gray-600 my-2 font-bold text-sm">{item.Role}</p>
                <p className="text-center text-gray-600 mt-1 text-xs">{item.university}</p>
                <div className='h-full grid items-end my-4'>
                <button className=' p-2 bg-red-500 text-white rounded-3xl' onClick={()=>handleCardClick(item)}> Abstract</button>
                </div>
              </div>
            </div>
          })
        }
      </div>
        </DialogBody>
        <Dialog open={open} handler={handleOpen} className=' p-2 overflow-y-auto   max-h-96  w-3/5'>
        <DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg' >
{bioData?.AbstractTitle && <h1 className='font-semibold text-red-500'>{
            bioData?.AbstractTitle
          }</h1>}

        </DialogHeader>
        <DialogBody>
          { bioData?.Abstract?.length>0 && <div className=''>

          {bioData?.Abstract.map((e,i)=>{
            return <div className='p-2'>
              <p className=''>{e.children[0].text}</p>
            </div>
          })}


          </div>}
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>Okay</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <DialogFooter>
<Button variant="gradient" color="green" onClick={props.handleOpen}>
            <span>Okay</span>
          </Button>
</DialogFooter>

    </Dialog>
  )
}

export default SpeakersModal