import React, { useEffect, useState } from 'react'

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";

import BioModal from './BioModal';

interface CardListProps {
  data: DataContent[];
}
interface DataContent {
  _id: number,
  name: string,
  url: string,
  Biography:BioType[],
  image: {
    asset: {
      _ref: string
    }
  }
}

interface BioType{
  children: Array<{
    text:string
  }>
}

const CardList = (props: CardListProps) => {

  return (
    <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
      {props.data.length > 0 ?
        <> {props.data.map((item, index) => {
          
          return (
            <>
            <Card className="relative bg-white w-52">
              
              <Tooltip
              interactive={true}

              content={
                <>
                
                <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
              {  
                item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                    
                     
                                      return <p> - {e.children[0].text} </p>
                   
                   
                  }):<h1 className=''> There is no Bio for this person</h1>
              }</>
              } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto" >
                <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                </span>
              </Tooltip>
              {/* </button> */}
              <CardHeader floated={false} className="h-40">
                {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
              </CardHeader>
              <CardBody className="text-center">
                <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                  {item.name}
                </Typography>
                <Typography color="blue-gray" className="font-medium" textGradient>
                  <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                </Typography>
              </CardBody>
            </Card>
          
            </>
          )
        })}</>
        : null}
        
    </div>
  )
}

export default CardList