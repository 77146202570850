import React, { useEffect, useState } from 'react'
import PreviousSSD from '../../sanityInterfaces/PreviousSSD'
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from '@material-tailwind/react';

interface modalProps {
    open: boolean;
    handleOpen: () => void;
    data:PreviousSSD;
  }
const TechnicalProgModal = (props:modalProps) => {
    
useEffect(() => {}, [props?.open])

  const TABLE_HEAD_1 = ["Communication, Signal Processing & Information Technology (CSP)", "Power Systems & Smart Energies (PSE)"]
  const TABLE_HEAD_2 = ["Systems, Automation & Control (SAC)", "Sensors, Circuits and Instrumentation Systems (SCI)"]
  console.log("handing open modal",props)
  return (
   

<Dialog size='xl' open={props.open} handler={props.handleOpen} className=' p-2 max-h-3/4 overflow-y-auto w-3/5'>
        <DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg m-2' >
    <h1 className='text-red-500'>Technical program {props.data?.SSDVersionYear}</h1>

        </DialogHeader>
        <DialogBody>
        <div className='p-4 '>

<div className="text-left mx-10 grid gap-3">
  <h1 className='text-2xl font-semibold text-red-400'>
    Plenaries
  </h1>
  
      <div className='grid grid-cols-2 gap-4'>
        {
          props?.data?.OpeningPL1?.asset?._ref ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.OpeningPL1.asset._ref.slice(5, -4) + "." + props?.data.OpeningPL1.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          Opening PL 1 File
        </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          Opening PL 1 File
        </a>
        }
        {
          props?.data?.PL2KLSAC1?.asset?._ref ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PL2KLSAC1.asset._ref.slice(5, -4) + "." + props?.data.PL2KLSAC1.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 2 KL SAC1 File
        </a> :<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 2 KL SAC1 File
        </a>
        }
        {
          props?.data?.PL3?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PL3.asset._ref.slice(5, -4) + "." + props?.data.PL3.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 3 File
        </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 3 File
        </a>
        }
        {
          props?.data?.PL4?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PL4.asset._ref.slice(5, -4) + "." + props?.data.PL4.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 4 File
        </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL 4 File
        </a>
        }
        {
          props?.data?.PL5KLSAC2?.asset ?<a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PL5KLSAC2.asset._ref.slice(5, -4) + "." + props?.data.PL5KLSAC2.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL5KLSAC2 File
        </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PL5KLSAC2 File
        </a>
        }
        {
          props?.data?.PhD3M?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PhD3M.asset._ref.slice(5, -4) + "." + props?.data.PhD3M.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PhD3M File
        </a>: <a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
          PhD3M File
        </a>
        }
      </div>


      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD_1.map((head) => (
              <th key={head} className="border-b w-1/2 border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          { props?.data?.CommunicationSignalProcessingInformationTechnology?.length>0 && props?.data?.CommunicationSignalProcessingInformationTechnology?.map((item, index) => {
            const isLast = index === props?.data.CommunicationSignalProcessingInformationTechnology.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={item.toString()}>
                <td  className={`${classes} + blue_td font-normal text-blue-500 hover:bg-blue-500 hover:text-white`}>
                  <Typography variant="small" color="blue-gray" >
                    {
                    item.file && <a  className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${item.file.asset._ref.slice(5, -4) + "." + item.file.asset._ref.slice(-3)}`}>{item.Title}</a>
                 }
                     </Typography>
                </td>
                <td className={`${classes} blue_td hover:bg-blue-500 hover:text-white bg-blue-gray-50/50`}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {
                    props?.data?.PowerSystemsSmartEnergies?.length>0 && props?.data?.PowerSystemsSmartEnergies[index]?.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PowerSystemsSmartEnergies[index].file.asset._ref.slice(5, -4) + "." + props?.data.PowerSystemsSmartEnergies[index].file.asset._ref.slice(-3)}`}>{props?.data.PowerSystemsSmartEnergies[index] && props?.data.PowerSystemsSmartEnergies[index].Title}</a>
                 }
                     </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD_2.map((head) => (
              <th key={head} className="border-b w-1/2 border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.data?.SystemsAutomationControl?.map((item, index) => {
            const isLast = index === props?.data.CommunicationSignalProcessingInformationTechnology.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={item.toString()}>
                <td className={`${classes} blue_td hover:bg-blue-500 hover:text-white`}>
                  <Typography variant="small" color="blue-gray" className="font-normal  ">
                    {
                      item.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${item.file.asset._ref.slice(5, -4) + "." + item.file.asset._ref.slice(-3)}`}>{item.Title}</a>
                
                    }
                      </Typography>
                </td>
                <td className={`${classes} bg-blue-gray-50/50 blue_td hover:bg-blue-500 hover:text-white`}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {
                    props?.data.PowerSystemsSmartEnergies[index]?.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.PowerSystemsSmartEnergies[index]?.file.asset._ref.slice(5, -4) + "." + props?.data.PowerSystemsSmartEnergies[index]?.file.asset._ref.slice(-3)}`}>{props?.data.PowerSystemsSmartEnergies[index] && props?.data.PowerSystemsSmartEnergies[index].Title}</a>
                 }
                     </Typography>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>


 
  
   <div className='grid gap-3'>
      {
        props?.data?.SSDProgramBook?.asset ? 
        <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.SSDAbstractBook.asset._ref.slice(5, -4) + "." + props?.data.SSDAbstractBook.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Abstract Book
      </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Abstract Book
      </a>
      }
      {
        props?.data?.SSDProgramBook?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.SSDProgramBook.asset._ref.slice(5, -4) + "." + props?.data.SSDProgramBook.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Program Book
      </a>: <a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Program Book
      </a>
      }
      {
        props?.data?.SSDSocialProgram?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.SSDSocialProgram.asset._ref.slice(5, -4) + "." + props?.data.SSDSocialProgram.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Social Program
      </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        SSD-Social Program
      </a>
      }
      
      {props?.data?.WS1SSDmeeting?.asset ?<a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.WS1SSDmeeting.asset._ref.slice(5, -4) + "." + props?.data.WS1SSDmeeting.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        WS-1–SSD meeting
      </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        WS-1–SSD meeting
      </a>}
      {
      props?.data?.WS2closure?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${props?.data.WS2closure.asset._ref.slice(5, -4) + "." + props?.data.WS2closure.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        WS-2–closure

      </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
        WS-2–closure

      </a>
      }</div>

</div>

</div>
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="green" onClick={props.handleOpen}>
            <span>Okay</span>
          </Button>
        </DialogFooter>
      </Dialog>

  )
}

export default TechnicalProgModal