import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react'
import React from 'react'
import PreviousSSD from '../../sanityInterfaces/PreviousSSD';
interface modalProps {
    open: boolean;
    handleOpen: () => void;
    data:PreviousSSD;
  }
const WorkshopsModal = (props:modalProps) => {
  return (
    <Dialog size='xl' className=' p-2 max-h-3/4 overflow-y-auto w-3/5' handler={props?.handleOpen} open={props?.open} >
<DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg m-2'>
    <h1 className='text-red-500'>Workshops</h1>
</DialogHeader>
<DialogBody>
<div className="p-4 my-4 grid gap-5 text-left space-y-2 mb-24">
        {
          props?.data?.Workshops && props?.data.Workshops.map((item, index) => {
            return <>

              <h1 className='text-red-500 text-3xl font-semibold'>{item.Title}</h1>
              <div className='border rounded-lg border-stale-200 p-4 mx-6'>
                <h2 className='text-xl'><span className='font-bold'>Speaker - </span> {item.Speaker}</h2>
                <p>
                  {item.Explanation[0].children[0].text}
                </p>
              </div>

            </>
          })
        }
      </div>

</DialogBody>
<DialogFooter>
<Button variant="gradient" color="green" onClick={props.handleOpen}>
            <span>Okay</span>
          </Button>
</DialogFooter>
    </Dialog>
  )
}

export default WorkshopsModal