
import './App.css';
import RoutesList from './components/RoutesList/RoutesList';
import Testimonials from './components/testimonials';
import Footer from './components/ui/footer';
import Header from './components/ui/header';


function App() {
  return (
    <div className="App">
      <Header />
      <RoutesList />
      <Footer />
    </div>
  );
}

export default App;
