import { TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography } from '@material-tailwind/react'
import React from 'react'
interface AimsAndScopeProps {
  conference: string;
  aimsAndScope: child[];
}
interface child{
  children:Content[];
}

interface Content{
  text:string;
}

const AimsAndScope: React.FC<AimsAndScopeProps> = ({ conference, aimsAndScope }) => {


  const ConferenceFetcher = (conference: any) => {
    switch (conference) {
      case "SAC":
        return SAC;
      case "PSE":
        return PSE;
      case "SCI":
        return SCI;
      case "CSP":
        return CSP;
      default:
        return [];
    }
  };

  const SAC = [
    {
      "Intelligent control": [
        "Fuzzy systems & fuzzy control",
        "Neuro-fuzzy systems",
        "Learning processes in control",
        "Neural Networks & Neural control",
        "Machine learning & artificial intelligence in control"
      ]
    },
    {
      "Complex systems": [
        "Analysis & control of large-scale autonomous networks",
        "Control & dynamics of complex network systems",
        "Agent-based control systems",
        "Discrete event systems",
        "Hierarchical & man-machine systems",
        "Infinite dimension systems",
        "Biological & economical models & control",
        "Biology-based and behavioral control"
      ]
    },
    {
      "Linear & nonlinear system analysis": [
        "Advances in linear systems",
        "Nonlinear systems",
        "System identification",
        "Hybrid systems"
      ]
    },
    {
      "Control approaches": [
        "Adaptive control",
        "Distributed control",
        "Geometric control",
        "Optimal & stochastic control",
        "Predictive control",
        "Robust control",
        "Sliding mode control",
        "System optimization",
        "Fractional order systems"
      ]
    },
    {
      "Faulty systems": [
        "Fault detection, diagnosis & prognostics",
        "Fault-tolerant control",
        "Machine learning for fault detection & diagnosis"
      ]
    },
    {
      "Robotics & automation": [
        "Robotics",
        "Traffic control",
        "Unmanned aerial vehicles",
        "Mechatronics"
      ]
    }
  ]

  const PSE = [
    {
      "Electric Machines": [
        "Modeling & design",
        "Machine control",
        "Variable speed drives",
        "Special machines"
      ]
    },
    {
      "Power Electronics": [
        "Converters",
        "Multilevel inverters",
        "Electromagnetic compatibility",
        "Facts",
        "Semiconductor devices"
      ]
    },
    {
      "Power Systems": [
        "Distributed power systems",
        "Smart grid & security",
        "Micro-Grids & virtual power plants",
        "Transformers",
        "Power quality",
        "Measurements & protection",
        "DC micro-grid",
        "Reconfiguration & power flow solutions",
        "Storage systems"
      ]
    },
    {
      "Renewable Energy Systems": [
        "Photovoltaic systems",
        "Concentrated solar plants",
        "Wind energy",
        "Tide & wind energy",
        "Smart grids & distribution networks",
        "Grid connected power electronics converters",
        "Hybrid and storage systems",
        "Fuel cells"
      ]
    },
    {
      "Automotive Power Systems": [
        "Hybrid electric vehicle",
        "Electric vehicle and safety",
        "Storage systems in EV",
        "Flywheel Energy"
      ]
    },
    {
      "Monitoring and Diagnosis": [
        "Variable speed drives and monitoring",
        "Fault detection and diagnosis",
        "Predictive diagnosis",
        "Tolerant control",
        "Pronostics"
      ]
    }
  ];

  const SCI = [
    {
      "Sensors": [
        "Physical and chemical sensors",
        "Mechanical and flow sensors",
        "Position and Radar sensors",
        "Temperature and humidity sensors",
        "Electrochemical and biomedical sensors",
        "Ultrasonic and acoustic sensors",
        "Magnetic and electromagnetic field sensors",
        "Optical and photonic sensors",
        "Surface acoustic wave sensors"
      ]
    },
    {
      "Measurement Methods and Metrology": [
        "Spectroscopy methods and systems",
        "Optical methods and systems",
        "Impedance spectroscopy",
        "Metrology and measurement uncertainty"
      ]
    },
    {
      "Sensors and Circuits Technology": [
        "MEMS and NEMS technology",
        "Molecular electronics",
        "Polymer sensors, actuators, and electronics",
        "Actuators and actuator technologies",
        "Optoelectronic devices",
        "Microfluidic and lab-on-chip devices",
        "Additive manufacturing",
        "Sensor testing and evaluation",
        "Aging, reliability, stability, and EMC"
      ]
    },
    {
      "Sensor and Measurement Systems": [
        "Signal conditioning and interface electronics",
        "Conversion and digitalization",
        "Data acquisition and distributed measurements",
        "Embedded systems",
        "Sensor arrays and matrices",
        "Multi-sensor system",
        "Sensor signal processing and modeling",
        "Multi-sensor data fusion"
      ]
    },
    {
      "Electronic Systems and Technologies": [
        "Design, modeling, and simulation",
        "Custom and semi-custom circuits",
        "Packaging and reliability",
        "Materials, devices, and interconnects",
        "Analog and mixed circuits design",
        "Low-voltage, low-power VLSI design",
        "Resonators and oscillators",
        "Sensor communication circuits",
        "Circuit test and device characterization"
      ]
    },
    {
      "Sensor Systems for IoT": [
        "Low-power sensor networks",
        "Sensor telemetry and monitoring",
        "Energy harvesting and conversion",
        "Energy management for low power systems",
        "Energy saving strategies",
        "Analog and digital low-power electronics",
        "Soft sensors"
      ]
    },
    {
      "Sensor and Instrumentation Applications": [
        "Industry and robotics",
        "Medicine and health",
        "Agriculture and environment",
        "Mobility and automotive",
        "Smart grids",
        "Smart buildings",
        "Smart cities",
        "Safety and security"
      ]
    }
  ];

  const CSP = [
    {
      "Signal Processing": [
        "Theory and methods",
        "Design and Implementation",
        "Speech, audio, and acoustic",
        "Image, video, and multidimensional signal processing",
        "Biomedical Signal and Image Processing"
      ]
    },
    {
      "Communication Systems and IoT": [
        "Wireless communications",
        "Wireless sensors networks",
        "Smart systems",
        "Optical systems",
        "Radar and sonar systems",
        "New IoT Communication Technologies",
        "Energy efficiency and sustainability in the IoT",
        "IoT security"
      ]
    },
    {
      "Emerging Technology Applications": [
        "E-health and health informatics",
        "Smart cities",
        "Big data analytics",
        "Cloud computing",
        "Cyber security",
        "Machine learning models",
        "Artificial intelligence technology",
        "Distributed and real-time systems",
        "Augmented and virtual reality",
        "Geographical information systems"
      ]
    },
    {
      "Engineering Education and Innovation": [
        "e-Learning and blended learning",
        "Remote laboratories",
        "Engineering design programs",
        "Multidisciplinary education",
        "Undergraduate research",
        "Strategic planning",
        "The curriculum of engineering studies",
        "Accreditation of engineering programs"
      ]
    }
  ];





  return (
    <div className='grid grid-cols-2 p-2 gap-6 justify-start' >

      {
        ConferenceFetcher(conference).map((entry, index) => {
          let key = Object.keys(entry)[0]
          let values = Object.values(entry)[0]
          return <div>
            <h1 className=' text-xl font-semibold text-red-500' >{key} :</h1>
            <ul>
              {
                values.map((item: any) => {
                  return (
                    <li className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white rounded-md border shadow-md'>{item}</li>
                  )
                })
              }
            </ul>
          </div>
        })
      }

    </div >
  )
}

export default AimsAndScope