
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Logo from './logo'
import Dropdown from '../utils/dropdown'
import client from '../../client/Client'
import PreviousSSD from '../../sanityInterfaces/PreviousSSD'
interface IeeeContent{
  image:string,
  link:string
}

export default function Header() {
  const [YearVersion, setYearVersion] = useState("");
  const [allPrevious, setAllPrevious] = useState<Array<{name:string,link:string}>>(
    []
  )
  const [IeeeContent, setIeeeContent] = useState<IeeeContent>({
    image:"",
    link:""
  })
  
  useEffect(() => {
    client.fetch('*[_type == "YearVersion"]')
      .then((result) => {
       if(result.length>0){
        setYearVersion(result[0].YearVersion)
     
       }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
      client.fetch('*[_type == "PreviousSSD"]')
      .then((result) => {
       if(result.length>0){
        let finalArray:Array<{name:string,link:string}>=[]
          
        result.forEach((element:PreviousSSD) => {
          console.log({name:element?.SSDVersionYear,link:"/learnmore/".concat(element?.SSDVersionYear)})
          finalArray.push({name:element?.SSDVersionYear,link:"/learnmore/".concat(element?.SSDVersionYear)})
        });
        
     setAllPrevious(finalArray)
     client.fetch('*[_type == "WelcomeParagh"]')
     .then((resul)=>{
      if(resul.length>0 && resul[0]?.IEEE?.asset?._ref && resul[0]?.IEEEWebsite ){
        
        const myObj = {
          image:resul[0]?.IEEE?.asset?._ref,
          link:resul[0]?.IEEEWebsite
        }
        console.log("did i set ?",myObj)
        setIeeeContent(myObj)

      }
     })
       }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])

  const [top, setTop] = useState<boolean>(true)

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true)
  }

  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top ? 'bg-white backdrop-blur-sm shadow-lg' : ''}`}>
      <div className="relative max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="shrink-0 flex space-x-4 items-center mr-8">
            <Logo />
            {IeeeContent?.image !=="" && IeeeContent?.link !==""  && <a href={IeeeContent?.link} className="block" aria-label="Cruip">
      <img
        src={`https://cdn.sanity.io/images/79vgbaej/production/${IeeeContent?.image.slice(6, -4) + '.' + IeeeContent?.image.slice(-3)}`}
        width={80}
        height={80}
        alt="IEEE Logo"
      />
    </a>}
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow overflow-visible">
            {/* Desktop sign in links */}
            <ul className="flex grow justify-end items-center">
              <li>
                <Dropdown title={'Conferences'} elements={[{ name: "SAC", link: "/sac" }, { name: "PSE", link: "/pse" }, { name: "SCI", link: "/sci" }, { name: "CSP", link: "/csp" }]} />
              </li>
              <li>
                <Dropdown title={'Committees'} elements={[{ name: "Chairs", link: "/chairs" }, { name: "Steering Committee", link: "/steering-committee" }, { name: "Scientific Committee", link: "/scientific-committee" }, { name: "Organizing Committee", link: "/organizing-committee" }, { name: "Ambassadors", link: "/ambassadors" }, { name: "Sponsors", link: "/sponsors" }, { name: "Organizers", link: "/organizers" }]} />
              </li>
              <li>
                <a href="/submissions" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Submission</a>
              </li>
              <li>
                <Dropdown title={'Programs'} elements={[{ name: "Technical Program ", link: `/technical-program/${YearVersion}` }, { name: "Speakers ", link: "/speakers" }, { name: "Special Sessions", link: "/special-sessions" }, { name: "Call for Special Sessions", link: "/call-for-special-sessions" }, { name: "PhD Student Contest", link: "/phd-student-contest" }, { name: "Workshop and Tutorial Sessions", link: "/workshop-and-tutorial-sessions" }, { name: "Workshops ", link: "/workshops" }, { name: "Social Program", link: "/social-program" }, { name: "Awards", link: "/awards" }]} />
              </li>
              <li>
                <Dropdown title={'Accomodations'} elements={[{ name: "Registration", link: "/registration" }, { name: "Travel Information", link: "/travel-information" }, { name: "Accommodation", link: "/accomodation" }, { name: "Hotel Information", link: "/hotel-inforamtion" }]} />
              </li>
              <li>
                <Dropdown title={'SSD'} elements={[{ name: "SSD in IEEE Xplore", link: "https://ieeexplore.ieee.org/xpl/conhome/1002453/all-proceedings" }, { name: "Top cited papers", link: "/top-cited-papers" }].concat(allPrevious)} />
              </li>
              <li>
                <Link to="/contactus" className="btn-sm p-2 px-6 rounded-md text-white bg-red-800 hover:bg-red-500 ml-3 grid items-center justify-center">
                  <span className='text-sm'>Contact Us</span>
                  </Link>
                </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
