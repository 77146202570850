import React, { useEffect, useState } from 'react'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client'
import { Tooltip } from '@material-tailwind/react'
import BioModal from '../utils/BioModal'
interface speakerContent {
  Role: string,
AbstractTitle:string,
Abstract:Array<{children:Array<{
  text:string,
}>}>,
  image: {
    asset: {
      _ref: string
    }
  },
  Biography:BioType[],
  linkedinUrl: string,
  name: string,
  profileUrl: string,
  university: string,

}
interface BioType{
  children: Array<{
    text:string
  }>
}
const Speakers = () => {

  const [speakers, setspeakers] = useState<speakerContent[]>([]);
  const [biodata, setBiodata] = useState<speakerContent>({
    Role: "",
  AbstractTitle:"",
  Abstract:[],
    image: {
      asset: {
        _ref: ""
      }
    },
    Biography:[],
    linkedinUrl: "",
    name: "",
    profileUrl: "",
    university: "",
  
  });
  const [open, setOpen] = useState(false);
  const handleOpen =() => {
   
    setOpen(!open);
  
  }
    const handleCardClick =(item: speakerContent) => {
 
      setBiodata(item);
      handleOpen()
    };
  useEffect(() => {
    client.fetch('*[_type == "SpeakerPage"]')
      .then((result) => {
        setspeakers(result[0].SpeakerPage);
        console.log(result)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Speakers' />
      <div className="p-4 gap-3 text-left grid grid-cols-3">
        {
          speakers && speakers.map((item, index) => {
            return <div className=" bg-white rounded-lg shadow-md p-5">
              <div className='relative flex flex-col h-full p-6 bg-white rounded shadow-xl'>
              <Tooltip 
                  interactive={true}
                  content={
                <>
                
                <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
              {  
                item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                    
                     
                                      return <p> - {e.children[0].text} </p>
                   
                   
                  }):<h1 className=''> There is no Bio for this person</h1>
              }
              </>
              } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto" 
               >
                <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                </span>
              </Tooltip>
                {item.image.asset && <img className="w-32 h-32 rounded-full mx-auto " src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + "." + item.image.asset._ref.slice(-3)}`} alt="Profile picture" />}
                <a href={item.profileUrl} className="text-center text-2xl font-semibold mt-3">{item.name}</a>
                <p className="text-center text-gray-600 my-2 font-bold text-sm">{item.Role}</p>
                <p className="text-center text-gray-600 mt-1 text-xs">{item.university}</p>
                <div className='h-full grid items-end my-4'>
                <button className=' p-2 bg-red-500 text-white rounded-3xl' onClick={()=>handleCardClick(item)}> Abstract</button>
                </div>
              </div>
            </div>
          })
        }
      </div>

     
<BioModal open={open} bioData={biodata} handleOpen={handleOpen} />
    </div>
  )
}

export default Speakers