import React, { useEffect, useState } from 'react'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client'

const PhdStudentcontent = () => {
  const [data, setData] = useState<{
    asset: {
      _ref: string
    }
  }>()
  const [otherdata, setOtherdata] = useState<{
    asset: {
      _ref: string
    }
  }>()

  useEffect(() => {
    client.fetch('*[_type == "Programs"]')
      .then((result) => {
        setData(result[0].PhDStudentContestImage);
        setOtherdata(result[0].PhDStudentContestFile);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 pb-32">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='PhD Student Contest' />

      {data?.asset && otherdata?.asset && <div className=" grid place-content-center p-4 text-left ">
        <a href={`https://cdn.sanity.io/files/79vgbaej/production/${otherdata?.asset._ref.slice(5, -4) + '.' + otherdata?.asset._ref.slice(-3)}`}>
          <img src={`https://cdn.sanity.io/images/79vgbaej/production/${data?.asset._ref.slice(6, -4) + '.' + data?.asset._ref.slice(-3)}`} alt='file ' />

        </a>
      </div>}
    </div>
  )
}

export default PhdStudentcontent