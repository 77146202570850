import React, { useEffect, useState } from 'react'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'
interface subChild {
  children: Array<{
    text: string
  }>
}
interface Child {
  Title: string,
  image: {
    asset: {
      _ref: string
    }
  },
  TheProgram: subChild[]
}
interface myData {
  SocialPrograms: Child[];
}
const SocialPrograms = () => {
  const [data, setData] = useState<myData>()

  useEffect(() => {
    client.fetch('*[_type == "Programs"]')
      .then((result) => {
       
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Social Program' />
      <div className=" my-4 grid  p-4 gap-5 text-left ">
        {
          data?.SocialPrograms && data?.SocialPrograms.map((item, index) => {
            return <>
              <h1 className="text-3xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter" >{item.Title}</h1>
              <div className='px-6 '>
                {item.image && (
                  <div className='float-left mr-6 p-1 w-1/2 rounded-lg border-2 border-red-200'>
                    <img className="  border w-full rounded-lg object-cover object-center" src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + "." + item.image.asset._ref.slice(-3)}`} />
                  </div>
                )}
                <p>{item.TheProgram.map((e, i) => {
                  return <p className='text-gray-600 mt-2 leading-loose'>
                    {e.children[0].text}
                  </p>
                })}</p>
              </div>
            </>
          })
        }

      </div >
    </div>
  )
}

export default SocialPrograms