import React from 'react'
import HeaderBanner from '../ui/HeaderBanner'

const Awards = () => {
    return (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
            <HeaderBanner title={'Awards'} />

            <div className=" my-4 grid  gap-5 text-left mb-24">
                <h1 className='text-3xl text-red-600 font-semibold'>Best Paper Awards</h1>
                <p>At every conference within SSD, a <span className='font-semibold'>Best Paper Award</span> will be provided to recognize the outstanding paper, which reports about an original and significant contribution to the corresponding field.</p>
                <p>A five-member jury of experts will carry out the selection of the best paper. The criteria for selecting the best paper are :</p>
                <ul className=' grid grid-cols-2 gap-4'>
                    <li className='cursor-pointer flex items-center space-x-3 py-4  font-bold text-gray-500 shadow-md text-center border border-gray-200 rounded-lg hover:bg-red-500 hover:text-white'>
                        <p className='text-center w-full'>Originality and degree of innovation</p>
                    </li>
                    <li className='cursor-pointer flex items-center space-x-3 py-4  font-bold text-gray-500 shadow-md text-center border border-gray-200 rounded-lg hover:bg-red-500 hover:text-white'>
                        <p className='text-center w-full'>Scientific, technical, or applied importance</p>
                    </li>
                    <li className='cursor-pointer flex items-center space-x-3 py-4  font-bold text-gray-500 shadow-md text-center border border-gray-200 rounded-lg hover:bg-red-500 hover:text-white'>
                        <p className='text-center w-full'>Review results</p>
                    </li>
                    <li className='cursor-pointer flex items-center space-x-3 py-4  font-bold text-gray-500 shadow-md text-center border border-gray-200 rounded-lg hover:bg-red-500 hover:text-white'>
                        <p className='text-center w-full'>Low similarity rating</p>
                    </li>
                    <li className='cursor-pointer flex items-center space-x-3 py-4  font-bold text-gray-500 shadow-md text-center border border-gray-200 rounded-lg hover:bg-red-500 hover:text-white'>
                        <p className='text-center w-full'>Clarity of writing and oral presentation</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Awards