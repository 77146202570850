
import { useState, useRef, useEffect } from 'react'
import { Transition } from '@headlessui/react'

import client from '../client/Client';
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';

interface dataItem {
  date: string;
  title: string;
}

export default function Features() {
  const navigate = useNavigate()
  const FeaturesBg = "/public/images/features-bg.png";
  const FeaturesElement = "/public/images/features-element.png"
  const [tab, setTab] = useState<number>(1)
  const [exploreSSD, setExploreSSD] = useState<string[]>([""])
  const [Data, setData] = useState<dataItem[]>([])
  const tabs = useRef<HTMLDivElement>(null)
  const staticData = [
    { title: "Regular Paper Submission", date: "2024-11-03" },
    { title: "Acceptance Notification", date: "2024-12-29" },
    { title: "Final Paper Submission", date: "2025-01-20" },
    { title: "Conference Start Date", date: "2025-02-17" }
  ]
  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
  }
  useEffect(() => {
    client.fetch('*[_type == "event"]') // Replace "PSE" with your actual content type name
      .then((result) => {
        setData(result[0].Event);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }, [])
    useEffect(() => {
      heightFix()
    }, [])
    useEffect(() => {
    client.fetch('*[_type == "WelcomeParagh"]')
      .then((result) => {
result[0]?.ExploreSSD && setExploreSSD(result[0]?.ExploreSSD?.split('\n\n'))
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    
  }, [])

  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>


      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16" data-aos="fade-top">
            <h1 className=" text-3xl font-semibold mb-4">Explore SSD</h1>
            <br />
            {exploreSSD?.map((e,i)=>{
              return <>
              <p className="text-l text-gray-600">{e}</p>
              <br />
              </>
            })}
          </div>

          {/* Section content */}
          <div id='dates-section' className="md:grid md:grid-cols-12 items-center" >

            {/* Content */}
            <div  className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6" data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="text-2xl font-semibold mb-3">IMPORTANT DATES AND DEADLINES</h3>
              </div>
              <div className="w-[32rem]">
                <Timeline >
                  {staticData ?
                    <>
                      {staticData.map((item, index) => {
                        return <TimelineItem>
                          <TimelineConnector />
                          <TimelineHeader className="h-3">
                            <TimelineIcon className='bg-red-600' />
                            <Typography variant="h6" color="blue-gray" className="leading-none">
                              {item.date}
                            </Typography>
                          </TimelineHeader>
                          <TimelineBody className="pb-8">
                            <Typography
                              variant="small"
                              color="gray"
                              className="font-normal text-gray-600"
                            >
                              {item.title}
                            </Typography>
                          </TimelineBody>
                        </TimelineItem>
                      })}

                    </>
                    : null}
                </Timeline>
              </div>
              {/* Tabs buttons */}
              {/* <div className="mb-8 md:mb-0">
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Building the Simple ecosystem</div>
                    <div className="text-gray-600">Take collaboration to the next level with security and administrative features built for teams.</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                    </svg>
                  </div>
                </a>
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Building the Simple ecosystem</div>
                    <div className="text-gray-600">Take collaboration to the next level with security and administrative features built for teams.</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                    </svg>
                  </div>
                </a>
                <a
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Building the Simple ecosystem</div>
                    <div className="text-gray-600">Take collaboration to the next level with security and administrative features built for teams.</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                    </svg>
                  </div>
                </a>
              </div> */}
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-left">
              <div className="max-w-sm mx-auto flex flex-row flex-wrap items-center justify-center md:max-w-2xl lg:max-w-none">
                {/* <div className="relative flex flex-col text-center lg:text-right max-w-xs" data-aos="zoom-y-out" ref={tabs}> */}
                <a href='/sac' className="relative w-64 h-60 flex flex-col items-center m-2 p-6 bg-white rounded shadow-xl cursor-pointer hover:shadow-2xl">
                  <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <rect className="fill-current text-red-600" width="64" height="64" rx="32" />
                      <g strokeWidth="2">
                        <path className="stroke-current text-red-300" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                        <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                        <path className="stroke-current text-red-300" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                        <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                      </g>
                    </g>
                  </svg>
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">SAC</h4>
                  <p className="text-gray-600 text-center">International Conference on Systems, Automation & Control</p>
                </a>
                <a href='/pse' className="relative w-64 h-60 flex flex-col items-center m-2 p-6 bg-white rounded shadow-xl cursor-pointer hover:shadow-2xl">
                  <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <rect className="fill-current text-red-600" width="64" height="64" rx="32" />
                      <g strokeWidth="2" transform="translate(19.429 20.571)">
                        <circle className="stroke-current text-white" strokeLinecap="square" cx="12.571" cy="12.571" r="1.143" />
                        <path className="stroke-current text-white" d="M19.153 23.267c3.59-2.213 5.99-6.169 5.99-10.696C25.143 5.63 19.514 0 12.57 0 5.63 0 0 5.629 0 12.571c0 4.527 2.4 8.483 5.99 10.696" />
                        <path className="stroke-current text-red-300" d="M16.161 18.406a6.848 6.848 0 003.268-5.835 6.857 6.857 0 00-6.858-6.857 6.857 6.857 0 00-6.857 6.857 6.848 6.848 0 003.268 5.835" />
                      </g>
                    </g>
                  </svg>
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">PSE</h4>
                  <p className="text-gray-600 text-center">International Conference on Power Systems & Smart Energies</p>
                </a>
                <a href='/csp' className="relative w-64 h-1/2 flex flex-col items-center m-2 p-6 bg-white rounded shadow-xl cursor-pointer hover:shadow-2xl">
                  <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <rect className="fill-current text-red-600" width="64" height="64" rx="32" />
                      <g strokeWidth="2">
                        <path className="stroke-current text-white" d="M19.429 32a12.571 12.571 0 0021.46 8.89L23.111 23.11A12.528 12.528 0 0019.429 32z" />
                        <path className="stroke-current text-red-300" d="M32 19.429c6.943 0 12.571 5.628 12.571 12.571M32 24a8 8 0 018 8" />
                        <path className="stroke-current text-white" d="M34.286 29.714L32 32" />
                      </g>
                    </g>
                  </svg>
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">CSP</h4>
                  <p className="text-gray-600 text-center">International Conference on Communication and Signal Processing & Information Technology</p>
                </a>
                <a href='/sci' className="relative w-64 h-60  flex flex-col items-center m-2 p-6 bg-white rounded shadow-xl cursor-pointer hover:shadow-2xl">
                  <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <rect className="fill-current text-red-600" width="64" height="64" rx="32" />
                      <g strokeWidth="2">
                        <path className="stroke-current text-red-300" d="M34.743 29.714L36.57 32 27.43 43.429H24M24 20.571h3.429l1.828 2.286" />
                        <path className="stroke-current text-white" strokeLinecap="square" d="M34.743 41.143l1.828 2.286H40M40 20.571h-3.429L27.43 32l1.828 2.286" />
                        <path className="stroke-current text-red-300" d="M36.571 32H40" />
                        <path className="stroke-current text-white" d="M24 32h3.429" strokeLinecap="square" />
                      </g>
                    </g>
                  </svg>
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">SCI</h4>
                  <p className="text-gray-600 text-center">International Conference on Sensors, Circuits and Instrumentation Systems</p>
                </a>
                {/* <Transition
                    show={tab === 1}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <Image className="md:max-w-none mx-auto rounded" src={FeaturesBg} width={500} height="462" alt="Features bg" />
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={FeaturesElement} width={500} height="44" alt="Element" style={{ top: '30%' }} />
                    </div>
                  </Transition>
                  <Transition
                    show={tab === 2}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <Image className="md:max-w-none mx-auto rounded" src={FeaturesBg} width={500} height="462" alt="Features bg" />
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={FeaturesElement} width={500} height="44" alt="Element" style={{ top: '30%' }} />
                    </div>
                  </Transition>
                  <Transition
                    show={tab === 3}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterFrom="opacity-0 translate-y-16"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-16"
                    beforeEnter={() => heightFix()}
                    unmount={false}
                  >
                    <div className="relative inline-flex flex-col">
                      <Image className="md:max-w-none mx-auto rounded" src={FeaturesBg} width={500} height="462" alt="Features bg" />
                      <Image className="md:max-w-none absolute w-full left-0 transform animate-float" src={FeaturesElement} width={500} height="44" alt="Element" style={{ top: '30%' }} />
                    </div>
                  </Transition> */}
                {/* </div> */}
              </div>
            </div>

          </div>

        </div>
      </div>
    </section >
  )
}