import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import HeaderBanner from '../ui/HeaderBanner';

interface content{
  FullAccommodationDoubleRoom:string,
FullAccommodationsingleroomsupplement:string,
FullAccommodationBaby:string,
FullAccommodationChild1:string,
FullAccommodationChild2:string,
FullAccommodationChild3:string,
HotelName:string
}

interface dataType{
  AccomodationDeadLine:string,
AccomodationForm:string,
FormAccommodation:Array<{
    children:Array<{
      text:string;
    }>
  }>
}
const Accomodation = () => {
  const [data, setData] = useState<dataType>(
   { AccomodationDeadLine:"",
    AccomodationForm:"",
      FormAccommodation:[]
      }
  )
 const [FullAccomodation, setFullAccomodation] = useState<content[]>([])
  useEffect(() => {
    client.fetch('*[_type == "AccomodationANDRegistration"]')
      .then((result) => {
        setData(result[0]);
        result[0].FullAccomodation.length>0 ? setFullAccomodation(result[0].FullAccomodation):<></>
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 mb-24">
      <HeaderBanner title={'Accomodation'}  />
      <>{console.log(data)}
</>
            <div className=" text-left grid gap-8">
        {data?.FormAccommodation?.length>0 &&  data?.FormAccommodation?.map((e,i)=>{
      
          return <div className='p-2 my-3 grid place-content-start'>
            <p className=''>{e?.children[0]?.text}</p>
          </div>
        })       
}
          
        { FullAccomodation.length>0 && FullAccomodation.map((item,index) => 
          {
           
           return <>  
           <h1 className='w-full grid justify-items-center text-xl font-semibold text-red-400' >{item.HotelName}</h1>
           <table className="rounded-t-lg m-5 w-full mx-auto bg-gray-200 text-gray-800">
            
              <>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">Double Room  </td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationDoubleRoom}</span></td>
  
                </tr>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">single room supplement</td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationsingleroomsupplement}</span></td>
  
                </tr>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">Baby (0 to 1.99 year)</td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationBaby}</span></td>
  
                </tr>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">Child (2 to 11.99 years) lodged with 2 adults</td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationChild1
                  }</span></td>
  
                </tr>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">Child (2 to 11.99 years) with 1 adult</td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationChild2
                  }</span></td>
  
                </tr>
                <tr>
                  <td className="px-4 py-3 text-left border-b-2 border-gray-300">2 Children (2 to 11.99 years) without adults</td>
                  <td className="px-4 py-3 bg-gray-100 border-b border-gray-200"><span className='w-full grid justify-items-center'>{item.FullAccommodationChild3}</span></td>
  
                </tr>
              </> 
          </table></>  
           
          })}
          
        

        {data && data.AccomodationDeadLine && data.AccomodationForm && <><h1 className=" text-sm  text-red-600 leading-loose" data-aos="zoom-out">The accommodation file should be fulfilled and sent to <a className='text-blue-500' href='mailto:registration@ssd-conf.org'>registration@ssd-conf.org</a> before {data.AccomodationDeadLine},  (see the attached file) in order to profit from the conference prices. </h1>
        <a className='text-blue-500 border p-6 py-4 text-lg font-bold shadow-md border-blue-500 rounded-md hover:bg-blue-400 hover:text-white' href={data.AccomodationForm}>Accommodation Form</a>
      </>
       } </div>

    </div>
  )
}

export default Accomodation