import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client';
import './style.css'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Avatar,
    IconButton,
    Typography,
    Card,
  } from "@material-tailwind/react";

import PreviousSSD from '../../sanityInterfaces/PreviousSSD';
import PreviousSSDLayout from './PreviousSSDLayout';
const LearnMore = () => {
    const {version} = useParams();
    const [thisYearVersion, setThisYearVersion] = useState("")
    
    const [versionData, setVersionData] = useState<PreviousSSD>(
        {
            image: {
              asset: {
                _ref: '',
              },
            },
            SSDVersionYear: '',
            SSDVersionImage: {
              asset: {
                _ref: '',
              },
            },
            SSDEditionNumber: '',
            Date: '',
            Place: '',
            Description: '',
            PHOTOGallery: [],
            OpeningPL1: {
              asset: {
                _ref: '',
              },
            },
            PL2KLSAC1: {
              asset: {
                _ref: '',
              },
            },
            PL3: {
              asset: {
                _ref: '',
              },
            },
            PL4: {
              asset: {
                _ref: '',
              },
            },
            PL5KLSAC2: {
              asset: {
                _ref: '',
              },
            },
            PhD3M: {
              asset: {
                _ref: '',
              },
            },
            CommunicationSignalProcessingInformationTechnology: [],
            PowerSystemsSmartEnergies: [],
            SystemsAutomationControl: [],
            SensorsCircuitsandInstrumentationSystems: [],
            SSDAbstractBook: {
              asset: {
                _ref: '',
              },
            },
            SSDProgramBook: {
              asset: {
                _ref: '',
              },
            },
            SSDSocialProgram: {
              asset: {
                _ref: '',
              },
            },
            WS1SSDmeeting: {
              asset: {
                _ref: '',
              },
            },
            WS2closure: {
              asset: {
                _ref: '',
              },
            },
            SSD_General_Chairs: [],
            SSDCOGENERALCHAIRS: [],
            SSDHONORARYCHAIRS: [],
            SSDORGANIZINGCOMMITTEECHAIRS: [],
            SACCHAIRS: [],
            CSPCHAIRS: [],
            SCICHAIRS: [],
            PSECHAIRS: [],
            PLENARYSESSIONSCHAIRS: [],
            PUBLICATIONCHAIRS: [],
            SpeakerPage: [],
            SpecialSession: [],
            Workshops: [],
          }
    )



    useEffect(() => {
        client.fetch('*[_type == "YearVersion"]')
        .then((result) => {
         setThisYearVersion(result[0].YearVersion.slice(-2))
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      
    }, [])
    
    useEffect(() => {

       
            client.fetch('*[_type == "PreviousSSD"]')
            .then((result) => {

             result.forEach((element:PreviousSSD) => {
                if (element.SSDVersionYear.slice(-2) === version?.slice(-2)) {
                    setVersionData(element);
                }
                
             });
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            }); 
        

    }, [version,thisYearVersion])


    
    
    
  return (
    <div className='max-w-6xl mx-auto px-4 sm:px-6 pt-32' >
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <PreviousSSDLayout  versionData={versionData} />
        
    </div>
  )
}

export default LearnMore