import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import HeaderBanner from '../ui/HeaderBanner';


interface OrganizingCommitee{
    LocalORGCOM:Array<{
        MemberName:string,
        Coutry:string
    }>
    IntORGCOM:Array<{
        MemberName:string,
        Coutry:string
    }>

}
const OrganizingCommittee = () => {
    const [orgCommittee, setOrgCommittee] = useState<OrganizingCommitee>({
        LocalORGCOM:[],
        IntORGCOM:[]
    })
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value: any) => setOpen(open === value ? 0 : value);
    useEffect(() => {
        client.fetch('*[_type == "OrgCommitees"]')
          .then((result) => {
            setOrgCommittee(result[0])
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Organizing Committee' />


        
        <Accordion open={open === 1}>
                <AccordionHeader onClick={() => handleOpen(1)}>Local Organizing Committee</AccordionHeader>
                <AccordionBody>
                  {orgCommittee?.LocalORGCOM?.length >0 ? 
                  
                  <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
           orgCommittee?.LocalORGCOM?.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>
                : <h1 className='font-semibold text-xl text-red-500'>Coming Soon</h1>  }
                </AccordionBody>
        </Accordion>
        <Accordion open={open === 2}>
                <AccordionHeader onClick={() => handleOpen(2)}>International Organizing Committee</AccordionHeader>
                <AccordionBody>
                  {orgCommittee?.IntORGCOM?.length >0 ? <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
            orgCommittee?.IntORGCOM?.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>:<h1 className='font-semibold text-xl text-red-500'>Coming Soon</h1> }
                </AccordionBody>
        </Accordion>
    </div>
  )
}

export default OrganizingCommittee