import React, { useEffect, useState } from 'react'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'
interface content{
  HotelName:string,
  HotelImage:{
    asset:{
      _ref: string
    }
  },
  HotelInformation:infoType[]
}

interface infoType{
  children:Array<{
    text:string
  }>
}

const HotelInfo = () => {

  const [data, setData] = useState<any>();
const [HotelInfo, setHotelInfo] = useState<content[]>([])
  const [imageData, setimageData] = useState<any[]>([])
  useEffect(() => {
    client.fetch('*[_type == "AccomodationANDRegistration"]')
      .then((result) => {
        setData(result[0]);
        setHotelInfo(result[0].HotelInformations)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])


  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='Hotel Information' />
        <div className=" p-4 text-left grid gap-3 ">

          {
            HotelInfo.length>0 && HotelInfo.map((item,index)=>{
              return <>
              {item.HotelName && <h1 className='text-2xl text-red-500 font-semibold '>{item?.HotelName} Info</h1>}
          
          {
           item?.HotelInformation?.length >0 && item?.HotelInformation.map((item, index) => {
              return (
                
                 <p className='text-xl'>- {item.children[0].text}</p>
                
              )
            })
          }
          {item.HotelName && <h1 className='text-2xl text-red-500 font-semibold '>{item?.HotelName} Images</h1>}
          <div className='float-left mr-6 p-1 w-1/2 rounded-lg border-2 border-red-200'>
           
              <img className=" border w-full rounded-lg object-cover object-center" src={`https://cdn.sanity.io/images/79vgbaej/production/${item.HotelImage.asset?._ref.slice(6, -4) + '.' + item.HotelImage.asset?._ref.slice(-3)}`} alt='hotel info' />
          
          </div>
              </>
            })
          }
           
          

        </div>
      <div  className='text-center border rounded-md p-4 shadow-md cursor-pointer text-blue-800 hover:text-white hover:bg-blue-500  my-6'> <a href={`${data?.AccomodationForm}`} className='font-semibold text-xl'>Accomodation form</a></div>
      </div>


    </>
  )
}

export default HotelInfo