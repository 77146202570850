import React, { useEffect, useState } from 'react'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react'



interface Content {
  name: string,
  image: {
    asset: {
      _ref: string
    }
  }
}
const Sponsors = () => {
  const [FinancialSponsors, setFinancialSponsors] = useState<Content[]>([])
  const [TechnicalSPonsors, setTechnicalSPonsors] = useState<Content[]>([])
  useEffect(() => {
    client.fetch('*[_type == "Sponsors"]')
      .then((result) => {
        setFinancialSponsors(result[0].FinancialSponsors);
        setTechnicalSPonsors(result[0].TechnicalSponsors);
      })
      .catch((error) => {
        console.error('Error fetching FinancialSponsors:', error);
      });
  }, [])
  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='Sponsors' />

        <div className="grid gap-4">
        <h1 className='text-2xl text-red-600 font-semibold'> Technical Sponsors:</h1>
          <div className=' p-8 flex flex-wrap items-center justify-items-center justify-center space-x-8 '>
 {TechnicalSPonsors && TechnicalSPonsors.map((item) => {
            return <Card className=" bg-white w-52">
            <CardHeader floated={false} className="h-40">
              {item.image.asset ? <img className='object-cover h-full w-full'  src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt='sponsor logo'  /> : null}
            </CardHeader>
            <CardBody className="text-center">
              <Typography color="blue-gray" className="font-medium" textGradient>
                {item.name}
              </Typography>
            </CardBody>
          </Card>
            
          })}
         
        </div>
        <h1 className='text-2xl text-red-600 font-semibold'> Financial Sponsors</h1>
        <div className=' p-8 flex flex-wrap items-center justify-items-center justify-center space-x-8'>
        {FinancialSponsors && FinancialSponsors.map((item) => {
            return <Card className=" bg-white w-52">
            <CardHeader floated={false} className="h-40">
              {item.image.asset ? <img className='object-cover h-full w-full'  src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt='sponsor logo'  /> : null}
            </CardHeader>
            <CardBody className="text-center">
              <Typography color="blue-gray" className="font-medium" textGradient>
                {item.name}
              </Typography>
            </CardBody>
          </Card>
            
          })}
        </div>
          
        </div>
      </div>
    </>
  )
}

export default Sponsors