

import { useState } from 'react'
import {Link} from "react-router-dom"

type DropdownProps = {
  children?: React.ReactNode
  title: string
  elements: Array<{ name: string, link: string }>
}

export default function Dropdown({
  children,
  title,
  elements
}: DropdownProps) {

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <div className=''>
      <div
        className=""
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
      >
        <Link
          className="relative font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
          to="#0"
          aria-expanded={dropdownOpen}
          onClick={(e) => e.preventDefault()}
        >
          {title}
          <svg className='w-5 h-5 ml-1' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 11L12 17L5 11" stroke={dropdownOpen ? "red" : "black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.5" d="M19 7L12 13L5 7" stroke={dropdownOpen ? "red" : "black"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
        {dropdownOpen &&
          <div
            className="absolute p-0.5 w-44 flex flex-col items-start transition-all shadow-lg rounded-sm mb-6 bg-white backdrop-blur-sm md:bg-opacity-90"
            data-aos="zoom-in-down"
            data-aos-duration="200"
          >
            {
              elements.map((element: { name: string, link: string }) => <a href={element.link} className="text-left font-medium text-sm w-full text-gray-600 hover:text-red-400 hover:bg-gray-200/30 px-5 py-3 flex items-center transition duration-150 ease-in-out rounded">{element.name}</a>)
            }
          </div>
        }
      </div>
    </div >
  )
}
