import { Button, Card, CardBody, CardHeader, Dialog, DialogBody, DialogFooter, DialogHeader, Tooltip, Typography } from '@material-tailwind/react'
import '../styles/ModalsStyle.css'

import PreviousSSD from '../../sanityInterfaces/PreviousSSD';
interface modalProps {
    open: boolean;
    handleOpen: () => void;
    data:PreviousSSD;
  }
const ChairsModal = (props:modalProps) => {
  return (
    <Dialog size='xl' className=' p-2 max-h-3/4 overflow-y-auto w-3/5' open={props?.open} handler={props?.handleOpen}>
        <DialogHeader className='p-3 border-2 border-gray-500 shadow-xl rounded-lg m-2'>
    <h1 className='text-red-500' >Chairs</h1>
        </DialogHeader>
        <DialogBody>
        <div className="grid justify-items-center">
        {props?.data ? (
          <div className=" grid gap-4 bg-gray-100/40 rounded-md p-4 w-full">
            <div className='p-2 flex '>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SSD General Chairs</h1>
            </div>
            <div className="flex flex-wrap">
              {props?.data.SSD_General_Chairs?.length > 0 ? (
                <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
                {props?.data?.SSD_General_Chairs?.length > 0 ?
                  <> {props?.data?.SSD_General_Chairs?.map((item, index) => {
                    
                    return (
                      <>
                      <Card className="relative bg-white w-52">
                        
                        <Tooltip
                        interactive={true}
          
                        content={
                          <>
                          
                          <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                        {  
                          item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                              
                               
                                                return <p> - {e.children[0].text} </p>
                             
                             
                            }):<h1 className=''> There is no Bio for this person</h1>
                        }</>
                        } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                          <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                          </span>
                        </Tooltip>
                        {/* </button> */}
                        <CardHeader floated={false} className="h-40">
                          {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                        </CardHeader>
                        <CardBody className="text-center">
                          <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                            {item.name}
                          </Typography>
                          <Typography color="blue-gray" className="font-medium" textGradient>
                            <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                          </Typography>
                        </CardBody>
                      </Card>
                    
                      </>
                    )
                  })}</>
                  : null}
                  
              </div>
              ) : null}
            </div>
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SSD Co-General Chairs</h1>
            </div>
            {props?.data.SSDCOGENERALCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.SSDCOGENERALCHAIRS?.length > 0 ?
                <> {props?.data.SSDCOGENERALCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Honorary Chairs</h1>
            </div>
            {props?.data.SSDHONORARYCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.SSDHONORARYCHAIRS?.length > 0 ?
                <> {props?.data.SSDHONORARYCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Organizing Committee Chairs</h1>
            </div>
            {props?.data.SSDORGANIZINGCOMMITTEECHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.SSDORGANIZINGCOMMITTEECHAIRS?.length > 0 ?
                <> {props?.data.SSDORGANIZINGCOMMITTEECHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SAC Chairs</h1>
            </div>
            {props?.data.SACCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.SACCHAIRS?.length > 0 ?
                <> {props?.data.SACCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >CSP Chairs</h1>
            </div>

            {props?.data.CSPCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.CSPCHAIRS?.length > 0 ?
                <> {props?.data.CSPCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SCI Chairs</h1>
            </div>
            {props?.data.SCICHAIRS?.length > 0 ? (
             <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
             {props?.data.SCICHAIRS?.length > 0 ?
               <> {props?.data.SCICHAIRS?.map((item, index) => {
                 
                 return (
                   <>
                   <Card className="relative bg-white w-52">
                     
                     <Tooltip
                     interactive={true}
       
                     content={
                       <>
                       
                       <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                     {  
                       item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                           
                            
                                             return <p> - {e.children[0].text} </p>
                          
                          
                         }):<h1 className=''> There is no Bio for this person</h1>
                     }</>
                     } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                       <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                         <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                       </span>
                     </Tooltip>
                     {/* </button> */}
                     <CardHeader floated={false} className="h-40">
                       {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                     </CardHeader>
                     <CardBody className="text-center">
                       <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                         {item.name}
                       </Typography>
                       <Typography color="blue-gray" className="font-medium" textGradient>
                         <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                       </Typography>
                     </CardBody>
                   </Card>
                 
                   </>
                 )
               })}</>
               : null}
               
           </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >PSE Chairs</h1>
            </div>
            {props?.data.PSECHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.PSECHAIRS?.length > 0 ?
                <> {props?.data.PSECHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Plenary Sessions Chairs</h1>
            </div>
            {props?.data.PLENARYSESSIONSCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.PLENARYSESSIONSCHAIRS?.length > 0 ?
                <> {props?.data.PLENARYSESSIONSCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Publication Chairs</h1>
            </div>
            {props?.data.PUBLICATIONCHAIRS?.length > 0 ? (
              <div className='flex justify-start gap-6 p-4 items-center flex-wrap'>
              {props?.data.PUBLICATIONCHAIRS?.length > 0 ?
                <> {props?.data.PUBLICATIONCHAIRS?.map((item, index) => {
                  
                  return (
                    <>
                    <Card className="relative bg-white w-52">
                      
                      <Tooltip
                      interactive={true}
        
                      content={
                        <>
                        
                        <h1 className=' text-red-400 text-xl font-semibold'>{item.name}</h1>
                      {  
                        item?.Biography?.length > 0 ? item.Biography.map((e,i)=>{
                            
                             
                                              return <p> - {e.children[0].text} </p>
                           
                           
                          }):<h1 className=''> There is no Bio for this person</h1>
                      }</>
                      } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto modal-tooltip" >
                        <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                        </span>
                      </Tooltip>
                      {/* </button> */}
                      <CardHeader floated={false} className="h-40">
                        {item.image && item.image.asset ? <img className='object-cover h-full w-full' src={`https://cdn.sanity.io/images/79vgbaej/production/${item.image.asset._ref.slice(6, -4) + '.' + item.image.asset._ref.slice(-3)}`} alt="profile face" /> : null}
                      </CardHeader>
                      <CardBody className="text-center">
                        <Typography color="blue-gray" className="mb-2 text-md font-semibold">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray" className="font-medium" textGradient>
                          <a href={item.url} className='text-blue-500 text-sm font-semibold' >{item.url}</a>
                        </Typography>
                      </CardBody>
                    </Card>
                  
                    </>
                  )
                })}</>
                : null}
                
            </div>
            ) : null}
          </div>
        ) : null}
      </div>
        </DialogBody>
        <DialogFooter>
<Button variant="gradient" color="green" onClick={props.handleOpen}>
            <span>Okay</span>
          </Button>
</DialogFooter>

    </Dialog>
  )
}

export default ChairsModal