

import CustomCard from './utils/card';
import { Carousel } from "@material-tailwind/react";
import { useEffect, useState } from 'react';
import client from '../client/Client';

interface CarouselItem {
  _id: string,
  Place: string,
  Date: string,
  SSDEditionNumber: string,
  image: {
    asset: {
      _ref: string
    }
  },
  SSDVersionYear: string,
  SSDURL: string,
  Description: string
}
export default function Hero() {

  const [welcomeParag, setWelcomeParag] = useState<string[]>([])
  const [ssdVersionData, setssdVersionData] = useState<CarouselItem[]>([])
  const [callForPapers, setCallForPapers] = useState("")
  
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []); 

  useEffect(() => {
    const pathD = "M8.25 4.5l7.5 7.5-7.5 7.5"; // Define the d attribute value
    const button = document.querySelector(`button svg path[d="${pathD}"]`)?.closest('button') as HTMLButtonElement;
    if (isMounted) {
      if (button) {
        button.click();
      }
    }
  }, [isMounted]);

  // useEffect(() => {
  //   client.fetch('*[_type == "WelcomeParagh"]')
  //   .then((result) => {
  //       let finalParag = result[0].content.split('\n\n');
        
        
  //       setWelcomeParag(finalParag);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, [])



  // Static data to replace the dynamic fetching
  const welcomeParagraph = [
    "The International Multi-Conference on Systems, Signals and Devices (SSD) co-sponsored by IEEE focuses on research, development and applications associated with the field of Electrical Engineering and Information Technology.",
    "The 22nd edition SSD’25 will be held in Monastir, Tunisia from 17th to 20th February 2025."
  ];

  useEffect(() => {
    client.fetch('*[_type == "WelcomeParagh"]')
    .then((result) => {
        
        if(result[0]?.CallForPapers?.asset?._ref){setCallForPapers(result[0]?.CallForPapers?.asset?._ref)
}
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [callForPapers])

  useEffect(() => {
    client.fetch('*[_type == "SSDVersion"]')
      .then((result) => {
        result.sort(function (a: any, b: any) {
          return +b.SSDVersionYear.slice(-2) - +a.SSDVersionYear.slice(-2);
        });
        setssdVersionData(result.reverse())
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [callForPapers])

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      {/* Hero content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-28 pb-12">
          <div className="text-center ">
            <h1 className="text-5xl md:text-4xl font-extrabold leading-tighter tracking-tighter mb-8" data-aos="zoom-out">The International Multi-Conference on Systems, Signals and Devices </h1>
            {/* <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">SSD</span> */}
            <div className="max-w-full flex flex-col justify-center items-center mx-auto">
              <div >{
                welcomeParagraph.length > 0 ?
                welcomeParagraph.map((item, index) => {
                    return <p className=" text-center text-xl text-gray-600 mb-8 max-w-3xl" data-aos="fade-in" data-aos-delay="200">{item}</p>
                  })
                  : null
              }</div>
                <div className='w-100 flex items-center space-x-6 pb-6'>
                  <a href={callForPapers ? `https://cdn.sanity.io/files/79vgbaej/production/${callForPapers.slice(5, -4) + "." + callForPapers.slice(-3)}`:""}  className='transition text-2xl font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    Call For Papers
                  </a>
                  <a href='#dates-section' className='transition text-2xl font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'> Important Dates</a>

                </div>
              <Carousel
              
              loop={true}
              transition={{type:"tween",duration:0.6}}
                className="rounded-xl w-full h-[50rem] shadow-md"
                navigation={({ setActiveIndex, activeIndex, length }) => {
                  
                  
                  return (
                  <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                      <span
                        key={i}
                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"}`}
                        onClick={() => setActiveIndex(i)}
                      />
                    ))}
                  </div>
                )}}
                data-aos="fade-in"
                data-aos-delay="200"
              >
                {ssdVersionData.length > 0 && ssdVersionData.map(({ _id,
                  Place,
                  Date,
                  SSDEditionNumber,
                  image,
                  SSDVersionYear,
                  SSDURL,
                  Description },index) => (
                  <CustomCard index={index} _id={_id} Place={Place} Description={Description} image={image} Date={Date} SSDVersionYear={SSDVersionYear} SSDURL={SSDURL} SSDEditionNumber={SSDEditionNumber} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}