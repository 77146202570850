import React, { useEffect, useState } from 'react'
import Features from '../features'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client'

interface Content{
  IEEETemplate:string,
  PDFExpressSubmission:{
    asset:{
      _ref:string
    }
  }
}
const Submissions = () => {
  const [EDAS, setEDAS] = useState<string>('')
  const [submissionContent, setSubmissionContent] = useState<Content[]>([])
  useEffect(() => {
    client.fetch('*[_type == "EDAS"]').then((response) => {
 
      if (response.length > 0) {
        setEDAS(response[0].EDAS);
  
      }
 
    })
   }, [])
   useEffect(() => {
    client.fetch('*[_type == "Submissions"]').then((response) => {
      
      if (response.length > 0) {
        setSubmissionContent(response);
  
      }
 
    })
   }, [])
  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 mb-24">
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='Submissions' />
        <div className=" text-left grid gap-4 mt-12 space-y-6">
          <h1 className=" text-md  font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">Paper submissions will be via the conference management system EDAS: <a className='text-blue-500' href={EDAS}> EDAS website.</a></h1>

          <h1 className='text-md '>Submitted papers should be written in English and limited to six pages including figures, tables, and references. Paper format and styles are available for download
          </h1>
          <ul className='grid grid-cols-2 gap-3'>
            <li className='text-center rounded-md px-4 py-3 border shadow-md cursor-pointer hover:bg-blue-400  text-blue-600 hover:text-white'>
              {submissionContent.length >0 && <a href={submissionContent[0].IEEETemplate}>IEEE Templates</a>}
            </li>
            <li className='text-center rounded-md px-4 py-3   border shadow-md cursor-pointer  hover:bg-blue-400  text-blue-600 hover:text-white'>
              {submissionContent.length > 0 && <a href={`https://cdn.sanity.io/files/79vgbaej/production/${submissionContent[0].PDFExpressSubmission.asset._ref.slice(5, -4) + "." + submissionContent[0].PDFExpressSubmission.asset._ref.slice(-3)}`} >PDF Express Submission</a>
          }
                </li>
          </ul>

          <h1 className=" text-red-400 text-sm  font-semibold leading-tighter tracking-tighter">Dear authors if you face any difficulties during  the submission of your paper,  please contact the organizing committee at the following emails: <a href="mailto:info@ssd.conf.org" className='text-blue-400 font-semibold'>info@ssd.conf.org</a></h1>

        </div>
      </div>
    </>
  )
}

export default Submissions