import React, { useEffect, useState } from "react";
import client from "../../client/Client";
import { Link } from "react-router-dom";
import HeaderBanner from "../ui/HeaderBanner";

interface myDATA {
  Email: string;
  Deadline: string;
  _type: string;
  _key: string;
  CallForSpecialSessionsTF: {
    _type: string;
    asset: {
      _ref: string;
      _type: string;
    };
  };
}

const CallforSpecialSessions = () => {
  const [data, setData] = useState<myDATA>();
  const [versionYear, setVersionYear] = useState("")

  useEffect(() => {
    client
      .fetch('*[_type == "Programs"]')
      .then((result) => {
      
        setData(result[0].CallForSpecialSessions[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
      client
      .fetch('*[_type == "YearVersion"]')
      .then((result) => {
      setVersionYear("20".concat(result[0].YearVersion?.slice(-2)))
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Call for Special Sessions' />

      <div className="p-4 text-left ">
        <h1
          className="text-3xl  text-red-500 mb-12 font-semibold leading-tighter tracking-tighter"
          data-aos="zoom-out"
        >
          Special Session Proposal
        </h1>

        <h1
          className="text-xl  text-red-500 my-4 font-semibold leading-tighter tracking-tighter"
          data-aos="zoom-out"
        >
          SSD Call for Special Sessions
        </h1>
        <p className="my-4">
          The IEEE International Multi-Conference on Systems, Signals and
          Devices {versionYear} (SSD {versionYear}) welcome and encourage the proposal of special
          sessions, which aim focusing on specific topics within the main
          objectives of the fourth conference. Indeed, in addition to the
          regular sessions, special sessions will helps authors to submit
          papers, which can cover related topics to each conference under
          subjects or cross-subjects of specific interests of innovation,
          updated research topics, current novel projects and new advance in
          <Link className="text-blue-600" to={"/sac"}> Systems, Automation & Control (SAC)</Link>, <Link className="text-blue-600" to={"/pse"}>Power Systems & Smart Energies
            (PSE)</Link>, <Link className="text-blue-600" to={"/csp"}>Communication and Signal Processing & Information Technology
              (CSP)</Link>,<Link className="text-blue-600" to={"/sci"}> Sensors, Circuits and Instrumentation Systems (SCI)</Link> .For details
          of each conference’s topics, please check with the link of each
          conference within the website of SSD {versionYear}.
        </p>
        {
          data && <p className="my-4">
            All interested researchers or academics who would like to propose and
            organize a special session should send their Special Sessions proposal
            to the Chairs of the special sessions at: <a className="text-blue-500" href={`mailto:${data?.Email}`}>{data?.Email}</a> . All
            proposals have to be submitted using the Special Session <a className="text-blue-600" href={`https://cdn.sanity.io/files/79vgbaej/production/${data?.CallForSpecialSessionsTF.asset._ref.slice(5, -4) + "." + data?.CallForSpecialSessionsTF.asset._ref.slice(-3)}`}>TEMPLATE</a> and
            including the following information:
          </p>
        }
        <ul >
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white  '>
            <p>Title of the Special Session</p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              The number of one special session organizers is not limited but
              preferred from at least two countries.
            </p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              The full names, affiliations and contact information of all the
              special session organizers should be provided
            </p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              A short abstract describing the covered specific topic in (approx.
              300 words). This abstract has to be ended with the answer of the
              question:
              <span className="font-semibold tet-red-500">
                {" "}
                Why you believe that the proposal is a significant and
                interesting subject?
              </span>
            </p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              A list of sub-topics related to the proposal (not more than 12
              sub-topics)
            </p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              A list of potential authors and their affiliations (not less than
              20)
            </p>
          </li>
          <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
            <p>
              A list of international potential reviewers and their affiliations
              (at least20, but not all from the same country)
            </p>
          </li>
        </ul>
        <div className="grid gap-7 mt-12">
          <h1
            className="text-xl  text-red-500 my-4 font-semibold leading-tighter tracking-tighter"
            data-aos="zoom-out"
          >Organization of special sessions</h1>
          <p className="mx-3">
            The proposal of Special Session will be reviewed by the Chairs of
            special sessions and the decision of approval will be provided to
            the organizers in less than 5 working days. Once the proposal is
            accepted and approved, it is the responsibility of the organizers to
            disseminate, distribute and publicize their Special Session (SS)
            among academics, students, researchers, industry and practitioners
            interested by the proposed topic of the SS to attract a sufficient
            number of submissions.
          </p>

          <p>
            The organizers ecial session should follow strictly the            rules of organizing a special session:
          </p>
          <ul>

            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p>The registrationprocess is the same as for regular papers.</p>
            </li>

            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p> The special session cannot accept the submission of more than two papers, which are co-authored by the same author (among the authors of the paper).</p>
            </li>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p>The review process will be the responsibility of the organizers of the special session and with follow-up by the Chairs of special sessions. However, the review process will undergo the same process as regular papers and the reviewers should not have any contact or interest with the authors of the paper.</p></li>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p> In case of conflict of interest, which means if the special session organizer has a paper co-authored by them, the review should be in a strict way where the review should choose in a way to avoid such conflict.</p></li>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p>The totalpercentage of the papers co-authored by at least one of the organizers should not in any way exceed 60%</p></li>

            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p> Allspecial session papers are presented as one or more oral sessions where each oral session should not include more than six papers.</p></li>

          </ul>
          <h1
            className="text-xl mt-12 text-red-500 my-4 font-semibold leading-tighter tracking-tighter"
            data-aos="zoom-out"
          >Important:</h1>
          <ul>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p>Free registration will  the organizers of a successfulspecial session for every 5 papers not co-authored by any author among the organizers. This free registration can be used by the organizers fully or divided among two or more organizers based on 100% of one free full registration (as an example two organizers can benefit from a 50% discount for each for one paper to each).
              </p>
            </li>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p>During the conference, the successful special session will be organized with the oral sessions, which will be chaired by the organizers who will attend the conference.
              </p>
            </li>
            <li className='cursor-pointer flex space-x-3 py-4 px-6 font-bold text-gray-500 shadow-md border border-gray-200 rounded-lg my-2 hover:bg-red-500 hover:text-white'>
              <p> If the organizer will not be able to attend the conference, the Chairs of the special sessions will delegate a colleague to chair the mentioned oral sessions.
              </p>
            </li>
          </ul>
          <p className="mx-2 my-3">  For any further information or enquiries concerning the organization of a special session please contact Special session organizer <a className=" text-blue-500" href={`mailto:${data?.Email}`}> {data?.Email}</a> </p>
          <p className="">Special Session Proposal Deadline: <span className="text-red-400">{data?.Deadline}</span></p>
        </div>
      </div>
    </div >
  );
};

export default CallforSpecialSessions;
