import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import HeaderBanner from '../ui/HeaderBanner';
const Travel = () => {

  type TravelType = {
    ArrivalToAirports: ArrivalToAirports[];
    RECOMMENDATIONVISARequirements: RECOMMENDATIONVISARequirements[];
    GeneralInformation: GeneralInformation[];
    GeneralInformationimage: GeneralInformationimage;
    LocalCurrency: LocalCurrency[];
  };

  interface ArrivalToAirports {
    _key: string;
    AirportImage?: {
      _type: string;
      asset: {
        _ref: string;
        _type: string;
      };
    };
    AirportName?: string; // Optional if not present in all objects
    AirportDescription?: {
      _key: string;
      markDefs: any[]; // You can define a specific type for markDefs if needed
      children: {
        marks: any[]; // You can define a specific type for marks if needed
        text: string;
        _key: string;
        _type: string;
      }[];
      _type: string;
      style: string;
    }[];
    _type: string;
  }

  interface RECOMMENDATIONVISARequirements {
    listItem: string; // Assuming the value can only be "bullet" or similar
    markDefs: any[];
    children: {
      marks: any[];
      text: string;
      _key: string;
      _type: string;
    }[];
    level: number;
    _type: string;
    style: string;
    _key: string;
  }

  interface GeneralInformation {
    _key: string;
    listItem: string; // Assuming the value can only be "bullet" or similar
    markDefs: any[];
    children: {
      marks: any[];
      text: string;
      _key: string;
      _type: string;
    }[];
    level: number;
    _type: string;
    style: string;
  }

  interface GeneralInformationimage {
    asset: {
      _type: string;
      _ref: string;
    };
    _type: string;
  }

  interface LocalCurrency {
    _key: string;
    listItem: string; // Assuming the value can only be "bullet" or similar
    markDefs: Link[]; // Define a specific type for markDefs
    children: Span[];
    level: number;
    _type: string;
    style: string;
  }

  interface Link {
    _type: string;
    href: string;
    _key: string;
  }

  interface Span {
    _key: string;
    _type: string;
    marks: string[];
    text: string;
  }

  const [data, setData] = useState<TravelType>()

  useEffect(() => {
    client.fetch('*[_type == "AccomodationANDRegistration"]')
      .then((result) => {
     
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [])
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Travel Information' />
      <div className="p-4 text-left grid gap-4 ">

        <h1 className="text-2xl text-red-500" data-aos="zoom-out">Recommendation Visa Requirements</h1>

        {
          data?.RECOMMENDATIONVISARequirements.map((item, index) => {
            return (
              <h1 className=" text-sm border p-4 rounded-md w-full shadow-md leading-tighter tracking-tighter hover:text-white hover:bg-red-500" data-aos="zoom-out">{item.children[0].text}</h1>
            )
          })
        }

        <h1 className="text-2xl text-red-500  " data-aos="zoom-out">General Information</h1>
        {
          data?.GeneralInformation.map((item, index) => {
            return (
              <h1 className=" text-sm" data-aos="zoom-out">{item.children[0].text}</h1>
            )
          })
        }
        <img className="h-96 w-full rounded-lg object-cover object-center" src={`https://cdn.sanity.io/images/79vgbaej/production/${data?.GeneralInformationimage?.asset._ref.slice(6, -4) + '.' + data?.GeneralInformationimage?.asset._ref.slice(-3)}`} alt='hotel info' />

        <h1 className="text-2xl text-red-500  " data-aos="zoom-out">Arrival to Airports</h1>

        {
          data?.GeneralInformation.map((item, index) => {
            return (
              <h1 className=" text-sm" data-aos="zoom-out">{item.children[0].text}</h1>
            )
          })
        }

        <div className='flex'>
          <h1 className=" text-sm leading-loose tracking-tighter" data-aos="zoom-out"></h1>
        </div>
        <div className='flex'>
          <h1 className=" text-sm leading-loose tracking-tighter" data-aos="zoom-out"></h1>
        </div>

        {
          data?.ArrivalToAirports?.map((item, index) => {
            return (
              <div className="text-sm space-y-6" data-aos="zoom-out ">
                <h1 className="text-xl text-blue-500 font-bold leading-loose tracking-tighter" data-aos="zoom-out">{item.AirportName}</h1>
                {item.AirportDescription?.map((description, index) => {
                  return (
                    <h1 className=" text-sm leading-loose tracking-tighter" data-aos="zoom-out">{description.children[0].text}</h1>
                  )
                })}
                <img className="h-96 w-1/2 rounded-lg object-cover object-center" src={`https://cdn.sanity.io/images/79vgbaej/production/${item.AirportImage?.asset._ref.slice(6, -4) + '.' + item.AirportImage?.asset._ref.slice(-3)}`} alt='hotel info' />
              </div>
            )
          })
        }

        {
          data?.LocalCurrency?.map((item, index) => {
            return (
              <div className="text-sm space-y-6" data-aos="zoom-out ">
                <h1 className="text-md text-gray-800 leading-loose tracking-tighter" data-aos="zoom-out">
                  {item.children.map((data, item) => {
                    return (<>
                      {data.text}
                    </>)
                  })}
                </h1>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default Travel