import React, { useEffect, useState } from 'react'
import client from '../../client/Client';
import { useParams } from 'react-router-dom';
import './HeaderBanner.css'

const HeaderBanner = (props: { title: string }) => {
  const pageVersion = useParams()
  const [version, setVersion] = useState<{
    YearVersion:string
  }>({
    YearVersion:""
  })
  useEffect(() => {
    if (props.title==='Previous version' || props.title==='Technical program') {

      
    }
    else{
      client.fetch('*[_type == "YearVersion"]')
      .then((result) => {
        setVersion(result[0])
     
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }
  }, [])
  return (
    <div className="relative flex items-center my-6">
  <div className='absolute grid justify-items-center  top-20     left-28 w-full z-10 '>
    {props.title==='Technical Program'?
    
    <h1 className="text-3xl title text-red-600  font-bold mb-6">
        Technical Program-SSD{pageVersion?.version}
      </h1>
    :props.title === 'Previous version'? (
      <h1 className="text-3xl title text-red-600  font-bold mb-6">
        {props.title}-{pageVersion.version}
      </h1>
    ) : (
      <h1 className="text-3xl title text-red-600  font-bold mb-6">
        {props.title}-SSD{version.YearVersion}
      </h1>
    )}
  </div>
  <section
    className="relative w-full h-64 bg-cover bg-center py-24"
    style={{
      backgroundImage: "url('/images/pulse1.png')",
      backgroundSize: "60%", // Adjust the value to your preference (e.g., 80%)
      backgroundRepeat: "no-repeat",
    }}
  >
    <div className="container mx-auto text-center text-white">
      {/* Your content goes here */}
    </div>
  </section>
</div>

  )
}

export default HeaderBanner

function setData(arg0: any) {
  throw new Error('Function not implemented.');
}
function setHotelInfo(HotelInformations: any) {
  throw new Error('Function not implemented.');
}

