import React, { useEffect, useState } from 'react'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

interface SpecialSession {
  _type: string;
  Title: string;
  OrganizedBY: Organizer[];
  YearVersion: string;
  CFPFSS: CFPFSSBlock[];
  PotentialContributingAuthors: Author[];
  PotentialReviewers: Reviewer[];
  TopicCovered: TopicCoveredBlock[];
  SSObjectives: SSObjectivesBlock[];
  SpecialNote: SpecialNoteBlock[];
  _id: string;
  _upUniversitydAt: string;
  _createdAt: string;
  _rev: string;
}

interface Organizer {
  children: OrganizerName[];
  _type: string;
}

interface OrganizerName {
  _type: string;
  marks: string[];
  text: string;
}

interface Author {
  _type: string;
  style: string;
  _key: string;
  markDefs: string[];
  children: AuthorInfo[];
  level?: number;
  listItem?: string;
}

interface AuthorInfo {
  _type: string;
  marks: string[];
  text: string;
}
interface TopicCoveredBlock {
  _type: string;
  style: string;
  _key: string;
  markDefs: string[];
  children: TopicCoveredContent[];
}

interface TopicCoveredContent {
  _type: string;
  marks: string[];
  text: string;
}

interface SSObjectivesBlock {
  style: string;
  _key: string;
  markDefs: string[];
  children: SSObjectivesContent[];
  _type: string;
}

interface SSObjectivesContent {
  _type: string;
  marks: string[];
  text: string;
}

interface SpecialNoteBlock {
  children: SpecialNoteContent[];
  _type: string;
  style: string;
  _key: string;
  markDefs: string[];
}

interface SpecialNoteContent {
  _type: string;
  marks: string[];
  text: string;
}

interface Reviewer {
  _type: string;
  _key: string;
  Name: string;
  Email: string;
  University: string;
}

interface CFPFSSBlock {
  _type: string;
  style: string;
  _key: string;
  markDefs: string[];
  children: CFPFSSContent[];
  listItem?: string;
  level?: number;
}

interface CFPFSSContent {
  _type: string;
  marks: string[];
  text: string;
}


const SpecialSessionsForReal = () => {

  const [data, setData] = useState<SpecialSession[]>()
  const [aimsData, setAimsData] = useState([])
  useEffect(() => {
    client.fetch('*[_type == "SSessions"]')
      .then((result) => {
        
        setData(result);
        setAimsData(result[0].AimsandScope);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])

  const [open, setOpen] = React.useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  const TABLE_HEAD = ["Name", "Email", "University"];

  return (

    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Special Sessions' />
      <div className='mb-12 '>
        {
          data && data.map((session, index) => {
            return (
              <Accordion open={open === index + 1}>
                <AccordionHeader onClick={() => handleOpen(index + 1)}>Seesion {index + 1} : {session.Title}</AccordionHeader>
                <AccordionBody>
                  <div className='space-y-6'>
                    {session.OrganizedBY && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Oragnized By</h1>
                        {
                          session.OrganizedBY.map((items, index) => {
                            return (
                              <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                {items.children[0].text}
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    {session.CFPFSS && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Call for Papers for Special Session</h1>
                        {
                          session.CFPFSS.map((items, index) => {
                            return (
                              <p className='p-2 leading-loose'>
                                {items.children[0].text}
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    {session.TopicCovered && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Topics Covered</h1>
                        {
                          session.TopicCovered.map((items, index) => {
                            return (
                              <>
                                {index === 0 && <p className='p-2 leading-loose'>
                                  {items.children[0].text}
                                </p>}
                                {index > 0 && <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                  {items.children[0].text}
                                </p>}
                              </>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    {session.PotentialContributingAuthors && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Potential Contributing Authors</h1>
                        {
                          session.PotentialContributingAuthors.map((items, index) => {
                            return (
                              <p className='p-2'>
                                {items.children[0].text} {items.children[1] && <a className="text-blue-300" href={items.children[1].text}>{items.children[1].text}</a>}
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    {session.PotentialReviewers && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Potential Reviewers (names and emails):</h1>

                        <p className='p-2'>
                          <table className="w-full min-w-max table-auto text-left">
                            <thead>
                              <tr>
                                {TABLE_HEAD.map((head) => (
                                  <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal leading-none opacity-70"
                                    >
                                      {head}
                                    </Typography>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {session.PotentialReviewers.map(({ Name, Email, University }, index) => {
                                const isLast = index === session.PotentialReviewers.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                return (
                                  <tr key={Name}>
                                    <td className={classes}>
                                      <Typography variant="small" color="blue-gray" className="font-normal">
                                        {Name}
                                      </Typography>
                                    </td>
                                    <td className={`${classes} bg-blue-gray-50/50`}>
                                      <Typography variant="small" color="blue-gray" className="font-normal">
                                        {Email}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography variant="small" color="blue-gray" className="font-normal">
                                        {University}
                                      </Typography>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {/* {items.children[0].text} {items.children[1] && <a className="text-blue-300" href={items.children[1].text}>{items.children[1].text}</a>} */}
                        </p>
                      </div>
                    )
                    }
                    {session.SSObjectives && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Special Session Objectives</h1>
                        {
                          session.SSObjectives.map((items, index) => {
                            return (
                              <p className='p-2 leading-loose'>
                                {items.children[0].text}
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    
                    {session.SpecialNote && (
                      <div>
                        <h1 className='text-xl font-bold text-red-500 mb-4'>Special Notes</h1>
                        {
                          session.SpecialNote.map((items, index) => {
                            return (
                              <p className='p-2 leading-loose'>
                                {items.children[0].text}
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                  </div>
                </AccordionBody>
              </Accordion>
            )
          })
        }
      </div>
    </div>
  )
}

export default SpecialSessionsForReal