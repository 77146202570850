import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import SAC from '../pages/SAC'
import SCI from '../pages/SCI'
import PSE from '../pages/PSE'
import CSP from '../pages/CSP'
import Chairs from '../pages/Chairs'
import SteeringCommittee from '../pages/SteeringCommittee'
import ScientificCommittee from '../pages/ScientificCommittee'
import Ambassadors from '../pages/Ambassadors'
import Organizers from '../pages/Organizers'
import Registration from '../pages/Registration'
import Accomodation from '../pages/Accomodation'
import Travel from '../pages/Travel'
import Sponsors from '../pages/Sponsors'
import Submissions from '../pages/Submissions'
import HotelInfo from '../pages/HotelInfo'
import TechnicalProg from '../pages/TechnicalProg'
import SpecialSessions from '../pages/SpecialSessions'
import PhdStudentcontent from '../pages/phdStudentcontent'
import WorkshopsAndTutorial from '../pages/WorkshopsAndTutorial'
import Workshops from '../pages/Workshops'
import SocialPrograms from '../pages/SocialPrograms'
import Awards from '../pages/Awards'
import SpecialSessionsForReal from '../pages/SpecialSessionsForReal'
import TopCitedPapers from '../pages/TopCitedPapers'
import Speakers from '../pages/Speakers'
import LearnMore from '../pages/LearnMore'
import ContactUs from '../pages/ContactUs'
import OrganizingCommittee from '../pages/OrganizingCommittee'

const RoutesList = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/sac' element={<SAC />} />
      <Route path='/sci' element={<SCI />} />
      <Route path='/pse' element={<PSE />} />
      <Route path='/csp' element={<CSP />} />
      <Route path='/chairs' element={<Chairs />} />
      <Route path='/steering-committee' element={<SteeringCommittee />} />
      <Route path='/scientific-committee' element={<ScientificCommittee />} />
      <Route path='/ambassadors' element={<Ambassadors />} />
      <Route path='/organizers' element={<Organizers />} />
      <Route path='/registration' element={<Registration />} />
      <Route path="/accomodation" element={<Accomodation />} />
      <Route path='/submissions' element={<Submissions />} />
      <Route path='/hotel-inforamtion' element={<HotelInfo />} />
      <Route path='/travel-information' element={<Travel />} />
      <Route path='/sponsors' element={<Sponsors />} />
      <Route path='/technical-program/:version' element={<TechnicalProg />} />
      <Route path='/call-for-special-sessions' element={<SpecialSessions />} />
      <Route path='/phd-student-contest' element={<PhdStudentcontent />} />
      <Route path='/workshop-and-tutorial-sessions' element={<WorkshopsAndTutorial />} />
      <Route path='/workshops' element={<Workshops />} />
      <Route path="/social-program" element={<SocialPrograms />} />
      <Route path='/awards' element={<Awards />} />
      <Route path='/special-sessions' element={<SpecialSessionsForReal />} />
      <Route path='/top-cited-papers' element={<TopCitedPapers />} />
      <Route path='/Speakers' element={<Speakers />} />
      <Route path='/learnmore/:version' element={<LearnMore />} />
      <Route path='/contactus' element={<ContactUs />} />
      <Route path='/organizing-committee' element={<OrganizingCommittee />} />
    </Routes>
  )
}

export default RoutesList