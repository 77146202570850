
import { createClient } from '@sanity/client';

const client = createClient({
  // projectId: '1jwf0bou',
  projectId: '79vgbaej',
  dataset: 'production', // Use 'production' or your desired dataset
  useCdn: true, // Enable the Content Delivery Network (CDN) for faster queries (recommended for production)
});

export default client;
