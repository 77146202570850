
import { Avatar, Button, Tooltip, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import client from "../client/Client";
interface speakerContent {
  Role: string,
  image: {
    asset: {
      _ref: string
    }
  },
  Biography:BioType[],
  linkedinUrl: string,
  name: string,
  profileUrl: string,
  university: string,
}

interface BioType{
  children: Array<{
    text:string
  }>
}

export default function FeaturesBlocks() {
  const [speakers, setspeakers] = useState<speakerContent[]>([])
  useEffect(() => {
    client.fetch('*[_type == "SpeakerPage"]')
      .then((result) => {
        
        setspeakers(result[0].SpeakerPage);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])

  return (
    <section className="relative">

      <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="text-2xl font-semibold mb-4">Speakers</h2>
            <p className="text-xl text-gray-600">The SSD conference brings together leading experts and researchers in the field of Electrical Engineering. Renowned keynote speakers share their expertise, while oral sessions provide a platform for knowledge exchange.</p>
          </div>

          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            {
              speakers.length > 0 && speakers.slice(0, 3).map((speaker, index) => {
                return <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl ">
                  <Tooltip 
                  interactive={true}
                  content={
                <>
                
                <h1 className=' text-red-400 text-xl font-semibold'>{speaker.name}</h1>
              {  
                speaker?.Biography?.length > 0 ? speaker.Biography.map((e,i)=>{
                    
                     
                                      return <p> - {e.children[0].text} </p>
                   
                   
                  }):<h1 className=''> There is no Bio for this person</h1>
              }
              </>
              } className="cursor-pointer max-h-44 max-w-xl overflow-y-auto" 
               >
                <span className='absolute z-10 w-8 h-8 rounded-full bg-white m-6 right-0'>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff0000"></circle> </g></svg>
                </span>
              </Tooltip>
                  <Avatar src={`https://cdn.sanity.io/images/79vgbaej/production/${speaker.image?.asset._ref.slice(6, -4) + '.' + speaker.image?.asset._ref.slice(-3)}`} alt="avatar" withBorder={true} size="xl" className="p-0.5 m-2" />
                  <h4 className="text-l font-bold leading-snug tracking-tight mb-1">{speaker.name}</h4>
                  <p className="text-gray-600 text-center">{speaker.Role}</p>
                  
                </div>
              })
            }
          </div>
          <div className="mt-6 w-full flex flex-row justify-end">
            <Link to="/speakers" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
              <span>See all Speakers</span>
              <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}