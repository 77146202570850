import React, { useEffect, useState } from 'react'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'
import { Typography } from '@material-tailwind/react'
import { useParams } from 'react-router-dom'
import "../styles/TechnicalProg.css"

interface dataArrayItem {
  Title: string,
  file: {
    asset: {
      _ref: string
    }
  }
}
interface myData {
  YearVersion:string,
  CommunicationSignalProcessingInformationTechnology: dataArrayItem[],
  PL2KLSAC1
  : {
    asset: {
      _ref: string
    }
  },
  OpeningPL1: {
    asset: {
      _ref: string
    }
  },
  PL3: {
    asset: {
      _ref: string
    }
  },
  PL4: {
    asset: {
      _ref: string
    }
  },
  PL5KLSAC2: {
    asset: {
      _ref: string
    }
  },
  PhD3M: {
    asset: {
      _ref: string
    }
  },
  PowerSystemsSmartEnergies: dataArrayItem[],
  SSDAbstractBook
  : {
    asset: {
      _ref: string
    }
  }, SSDProgramBook
  : {
    asset: {
      _ref: string
    }
  },

  SSDSocialProgram
  : {
    asset: {
      _ref: string
    }
  },
  SensorsCircuitsandInstrumentationSystems
  : dataArrayItem[],
  SystemsAutomationControl: dataArrayItem[],
  WS1SSDmeeting
  : {
    asset: {
      _ref: string
    }
  },
  WS2closure
  : {
    asset: {
      _ref: string
    }
  },
}
const TechnicalProg = () => {
  const params = useParams()

  const [data, setData] = useState<myData>({
    YearVersion:"",
    CommunicationSignalProcessingInformationTechnology: [],
  PL2KLSAC1
  : {
    asset: {
      _ref: ""
    }
  },
  OpeningPL1: {
    asset: {
      _ref: ""
    }
  },
  PL3: {
    asset: {
      _ref: ""
    }
  },
  PL4: {
    asset: {
      _ref: ""
    }
  },
  PL5KLSAC2: {
    asset: {
      _ref: ""
    }
  },
  PhD3M: {
    asset: {
      _ref: ""
    }
  },
  PowerSystemsSmartEnergies: [],
  SSDAbstractBook
  : {
    asset: {
      _ref: ""
    }
  }, SSDProgramBook
  : {
    asset: {
      _ref: ""
    }
  },

  SSDSocialProgram
  : {
    asset: {
      _ref: ""
    }
  },
  SensorsCircuitsandInstrumentationSystems
  : [],
  SystemsAutomationControl: [],
  WS1SSDmeeting
  : {
    asset: {
      _ref: ""
    }
  },
  WS2closure
  : {
    asset: {
      _ref: ""
    }
  },
  })

  useEffect(() => {
    client.fetch('*[_type == "TechnicalProgram"]')
      .then((result) => {
        if(result.length > 1) {
          result.forEach((element:any) => {
            if (element.YearVersion === params.version) {
              setData(element)
            }
          });

        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])



  const TABLE_HEAD_1 = ["Communication, Signal Processing & Information Technology (CSP)", "Power Systems & Smart Energies (PSE)"]
  const TABLE_HEAD_2 = ["Systems, Automation & Control (SAC)", "Sensors, Circuits and Instrumentation Systems (SCI)"]


  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='Technical Program' />
        <div className='p-4 '>

          <div className="text-left mx-10 grid gap-3">
            <h1 className='text-2xl font-semibold text-red-400'>
              Plenaries
            </h1>
            
                <div className='grid grid-cols-2 gap-4'>
                  {
                    data?.OpeningPL1?.asset?._ref ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.OpeningPL1.asset._ref.slice(5, -4) + "." + data.OpeningPL1.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    Opening PL 1 File
                  </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    Opening PL 1 File
                  </a>
                  }
                  {
                    data?.PL2KLSAC1?.asset?._ref ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PL2KLSAC1.asset._ref.slice(5, -4) + "." + data.PL2KLSAC1.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 2 KL SAC1 File
                  </a> :<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 2 KL SAC1 File
                  </a>
                  }
                  {
                    data?.PL3?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PL3.asset._ref.slice(5, -4) + "." + data.PL3.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 3 File
                  </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 3 File
                  </a>
                  }
                  {
                    data?.PL4?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PL4.asset._ref.slice(5, -4) + "." + data.PL4.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 4 File
                  </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL 4 File
                  </a>
                  }
                  {
                    data?.PL5KLSAC2?.asset ?<a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PL5KLSAC2.asset._ref.slice(5, -4) + "." + data.PL5KLSAC2.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL5KLSAC2 File
                  </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PL5KLSAC2 File
                  </a>
                  }
                  {
                    data?.PhD3M?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PhD3M.asset._ref.slice(5, -4) + "." + data.PhD3M.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PhD3M File
                  </a>: <a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                    PhD3M File
                  </a>
                  }
                </div>


                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_1.map((head) => (
                        <th key={head} className="border-b w-1/2 border-blue-gray-100 bg-blue-gray-50 p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    { data?.CommunicationSignalProcessingInformationTechnology?.length>0 && data?.CommunicationSignalProcessingInformationTechnology?.map((item, index) => {
                      const isLast = index === data.CommunicationSignalProcessingInformationTechnology.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={item.toString()}>
                          <td  className={`${classes} + blue_td font-normal text-blue-500 hover:bg-blue-500 hover:text-white`}>
                            <Typography variant="small" color="blue-gray" >
                              {
                              item.file && <a  className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${item.file.asset._ref.slice(5, -4) + "." + item.file.asset._ref.slice(-3)}`}>{item.Title}</a>
                           }
                               </Typography>
                          </td>
                          <td className={`${classes} blue_td hover:bg-blue-500 hover:text-white bg-blue-gray-50/50`}>
                            <Typography variant="small" color="blue-gray" className="font-normal">
                              {
                              data?.PowerSystemsSmartEnergies?.length>0 && data?.PowerSystemsSmartEnergies[index]?.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PowerSystemsSmartEnergies[index].file.asset._ref.slice(5, -4) + "." + data.PowerSystemsSmartEnergies[index].file.asset._ref.slice(-3)}`}>{data.PowerSystemsSmartEnergies[index] && data.PowerSystemsSmartEnergies[index].Title}</a>
                           }
                               </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_2.map((head) => (
                        <th key={head} className="border-b w-1/2 border-blue-gray-100 bg-blue-gray-50 p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.SystemsAutomationControl?.map((item, index) => {
                      const isLast = index === data.CommunicationSignalProcessingInformationTechnology.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={item.toString()}>
                          <td className={`${classes} blue_td hover:bg-blue-500 hover:text-white`}>
                            <Typography variant="small" color="blue-gray" className="font-normal  ">
                              {
                                item.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${item.file.asset._ref.slice(5, -4) + "." + item.file.asset._ref.slice(-3)}`}>{item.Title}</a>
                          
                              }
                                </Typography>
                          </td>
                          <td className={`${classes} bg-blue-gray-50/50 blue_td hover:bg-blue-500 hover:text-white`}>
                            <Typography variant="small" color="blue-gray" className="font-normal">
                              {
                              data.PowerSystemsSmartEnergies[index]?.file && <a className='text-blue-500' href={`https://cdn.sanity.io/files/79vgbaej/production/${data.PowerSystemsSmartEnergies[index]?.file.asset._ref.slice(5, -4) + "." + data.PowerSystemsSmartEnergies[index]?.file.asset._ref.slice(-3)}`}>{data.PowerSystemsSmartEnergies[index] && data.PowerSystemsSmartEnergies[index].Title}</a>
                           }
                               </Typography>
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>


           
            
             <div className='grid gap-3'>
                {
                  data?.SSDProgramBook?.asset ? 
                  <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.SSDAbstractBook.asset._ref.slice(5, -4) + "." + data.SSDAbstractBook.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Abstract Book
                </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Abstract Book
                </a>
                }
                {
                  data?.SSDProgramBook?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.SSDProgramBook.asset._ref.slice(5, -4) + "." + data.SSDProgramBook.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Program Book
                </a>: <a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Program Book
                </a>
                }
                {
                  data?.SSDSocialProgram?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.SSDSocialProgram.asset._ref.slice(5, -4) + "." + data.SSDSocialProgram.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Social Program
                </a>:<a href='' className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  SSD-Social Program
                </a>
                }
                
                {data?.WS1SSDmeeting?.asset ?<a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.WS1SSDmeeting.asset._ref.slice(5, -4) + "." + data.WS1SSDmeeting.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  WS-1–SSD meeting
                </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  WS-1–SSD meeting
                </a>}
                {
                data?.WS2closure?.asset ? <a href={`https://cdn.sanity.io/files/79vgbaej/production/${data.WS2closure.asset._ref.slice(5, -4) + "." + data.WS2closure.asset._ref.slice(-3)}`} className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  WS-2–closure

                </a>:<a href="" className='transition text-md font-semibold text-red-400 rounded-md border shadow-md px-4 py-3 hover:bg-red-500 hover:text-white'>
                  WS-2–closure

                </a>
                }</div>

          </div>

        </div>
      </div>




    </>
  )
}

export default TechnicalProg