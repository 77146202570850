import React, { useEffect, useState } from 'react'
import Features from '../features'
import CardList from '../utils/cardlist'
import Aimsandscope from '../utils/aimsandscope'
import client from '../../client/Client'
import HeaderBanner from '../ui/HeaderBanner'



interface child{
  children:Content[];
}

interface Content{
  text:string;
}
const SAC = () => {
  const [data, setData] = useState([])
  const [aimsData, setAimsData] = useState<child[]>([])
  const [EDAS, setEDAS] = useState<string>('')
 
  useEffect(() => {
    client.fetch('*[_type == "SAC"]')
      .then((result) => {
        setData(result[0].SAC_Chairs);
        setAimsData(result[0].AimsandScope);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  useEffect(() => {
    client.fetch('*[_type == "EDAS"]').then((response) => {
 
      if (response.length > 0) {
        setEDAS(response[0].EDAS);
  
      }
 
    })
   }, [])
  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='SAC' />
        <div className="p-4 bg-gray-100/40 text-left ">
          <h1 className="text-3xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">Welcome to the International Conference on Systems, Automation & Control </h1>
          <p className='mb-20'>The International Conference on Systems, Automation & Control (SAC) is a forum  for researchers and specialists  in fields related to control and automation</p>
          <h1 className="text-3xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">SAC Chairs </h1>
          <CardList data={data} />
          <div className='p-3 my-6  rounded-md s'>
            <h1 className="text-3xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">Aims and Scope</h1>
            <p className='my-4'>The International Conference on Systems, Automation & Control (SAC) covers the following topics:</p>
            <Aimsandscope conference='SAC' aimsAndScope={aimsData} />
            <p className='my-4'>SSD-SAC participants will have the possibility to attend all SSD conferences taking place in <b>parallel</b>.</p>
          </div>
          <div className='p-3 my-6  rounded-md s'>
            <h1 className="text-3xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter" data-aos="zoom-out">Paper Submission</h1>
            <p className='my-4'>Papers submitted to SSD-SAC should be written in English and limited to six pages including figures, tables, and references. Paper format and styles are available for download.</p>
            <p className='my-4'>All papers will be peer-reviewed on the basis of full manuscripts and acceptance will be based on quality, originality, and relevance. Selected papers at SSD conferences will be offered an opportunity to publish their work (after extension) to some international books and journals.</p>
            <br />
            <a href='https://www.ieee.org/conferences_events/conferences/publishing/templates.html' className='bg-blue-500 p-3 hover:bg-blue-400 text-white rounded-md shadow-md' >Access IEEE template</a>
            <br />
            {EDAS!=='' && <p className='my-4'>Paper submission should be done electronically through the <a href={EDAS} className='text-blue-500'>EDAS website</a>. Instructions for using the system for the submission of papers, please have a look either at the Author Instructions or the <a href="http://edas.info/doc/" className='text-blue-500'>EDAS online documentation.</a></p>
          }    </div>
        </div>
      </div>
    </>
  )
}

export default SAC