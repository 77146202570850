import React from "react";
import CardList from "../utils/cardlist";
import { useEffect, useState } from "react";
import client from "../../client/Client";
import HeaderBanner from "../ui/HeaderBanner";
import { useParams } from "react-router-dom";


interface DataContent {
  _id: number,
  name: string,
  url: string,
  Biography:Array<{
    children: Array<{
      text:string
    }>
  }>,
  image: {
    asset: {
      _ref: string
    }
  }
}

interface Response {
  SSD_General_Chairs: DataContent[];
  SSDCOGENERALCHAIRS: DataContent[];
  SSDHONORARYCHAIRS: DataContent[];
  SSDORGANIZINGCOMMITTEECHAIRS: DataContent[];
  SACCHAIRS: DataContent[];
  CSPCHAIRS: DataContent[];
  SCICHAIRS: DataContent[];
  PSECHAIRS: DataContent[];
  PLENARYSESSIONSCHAIRS: DataContent[];
  PUBLICATIONCHAIRS: DataContent[];
}

const Chairs = () => {
  const params = useParams();
  const [chairsData, setChairsData] = useState<Response>();

  useEffect(() => {
    client
      .fetch('*[_type == "chairs"]')
      .then((result) => {
        setChairsData(result[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    client
      .fetch('*[_type == "YearVersion"]')
      .then((result) => {
console.log(result)      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title={'Chairs'} />
      <div className="grid justify-items-center">
        {chairsData ? (
          <div className=" grid gap-4 bg-gray-100/40 rounded-md p-4 w-full">
            <div className='p-2 flex '>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SSD General Chairs</h1>
            </div>
            <div className="flex flex-wrap">
              {chairsData.SSD_General_Chairs?.length > 0 ? (
                <CardList data={chairsData.SSD_General_Chairs} />
              ) : null}
            </div>
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SSD Co-General Chairs</h1>
            </div>
            {chairsData.SSDCOGENERALCHAIRS?.length > 0 ? (
              <CardList data={chairsData.SSDCOGENERALCHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Honorary Chairs</h1>
            </div>
            {chairsData.SSDHONORARYCHAIRS?.length > 0 ? (
              <CardList data={chairsData.SSDHONORARYCHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Organizing Committee Chairs</h1>
            </div>
            {chairsData.SSDORGANIZINGCOMMITTEECHAIRS?.length > 0 ? (
              <CardList data={chairsData.SSDORGANIZINGCOMMITTEECHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SAC Chairs</h1>
            </div>
            {chairsData.SACCHAIRS?.length > 0 ? (
              <CardList data={chairsData.SACCHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >CSP Chairs</h1>
            </div>

            {chairsData.CSPCHAIRS?.length > 0 ? (
              <CardList data={chairsData.CSPCHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >SCI Chairs</h1>
            </div>
            {chairsData.SCICHAIRS?.length > 0 ? (
              <CardList data={chairsData.SCICHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >PSE Chairs</h1>
            </div>
            {chairsData.PSECHAIRS?.length > 0 ? (
              <CardList data={chairsData.PSECHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Plenary Sessions Chairs</h1>
            </div>
            {chairsData.PLENARYSESSIONSCHAIRS?.length > 0 ? (
              <CardList data={chairsData.PLENARYSESSIONSCHAIRS} />
            ) : null}
            <div className='flex'>
              <img className='w-8 mx-4' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2aOYgUURCGn4omu9P1V4/rGokooqiIieK1qIGgiQceiahgIopnYmqkrqCBCCaG3gpGsjqvelgEQxMPRNYrUkFF8MLVwJXq7nFnm+mZ7rWHmcYteMH0665XX9fxat6MMWPyn8gNYyZZ4JQQ3grhjQC9es3kTQToFfDQyIFekzcR9UQExBLembyJhMaXmZeVmJdXPrdXzCMaMsOjcv/fa8wrPKAndr7WoCbmVO2YTwCScl5GAPHJ7EEaeKI5IHibPUiShVPkQNb6TNYLa0400lWdM+0LgmyHGQOJkTGPYCy0htoiRyxwU5iX3i8WC/2dnZMF2GXBH3OW7LheS8cDYyZa4FpuQKzrLonTU3acmbkBues4bpwe9UpuQIRoRpyeIF/yAsK8Nk6PBVbmBsQDeuL0eI67JjcgtlCYFavHKS7KDwi4P1YP8b3cgAh4qES0MKrDAxak1WNaDSLgclSHJfTlEeR5DZAn+QMhvl0D5E5uQCzhhxCfv03EUR1h83hBwIPtC0L4JsTHSx0dUxrp6u/qmqrHSz50Ar0WeCbEl63D2/75rKvBYm/uFotz0uosMc8X8Ps0oWbBL0pEm5oE4m4erV4L7BgZmvxQgIOe684tdXd36Cgzz7PAISF+NHwveo8ZMz5TkP6urs4MTjAHPYf31DNO54R573COjeJ0fxTlN80Y1GbSJLWFaFUFxiPa2DYg6olG698wZoIQnysXirMDe7AvfP65fsdpOYjmRJJYF3KPhM8MKFQAhseBV9wtLQcR4ECjte8QTbeEr0HVwvrKdUt0OHwZl1oOotWp3rpDxoyzgNQyWKtZCPes5SB6XFS9jo0kvZC7OzCWP2hnUD2nzwaA+NJykOrS7REfDd/8WfVE0AHwpzAEt0dt6nNdx58jfE4O4v8c1tzQEmCnBf8KDdee7Fb4xvvqdAYpQ4v4ZDNAdMeuXsdjXuf3bZV5whdhnla3khFfTAyizZoPk7VniB9Fy6/nuIs1J+pVNS2/le83qcpv1uK/GPAr31jmvdH5cqE4WwhX4vYYC+wPX8ZAqg2xGaLtRWjMoLYdJvlzqwX804J/V+8rLRULnPkLA+zTkIm71w+nwBM/w+p2wrSLaJkV4PRwzuCx7ti62Wlp1uFXJ3KPVHLC9wTxiVG18c0WATYI+HWCAjHQNuEUJ/7fRMjdaglXLfilEL77PRbhqZZYrU5xif0HQkqgXE0d34MAAAAASUVORK5CYII=" />
              <h1 className='text-left text-xl text-red-500 font-bold' >Publication Chairs</h1>
            </div>
            {chairsData.PUBLICATIONCHAIRS?.length > 0 ? (
              <CardList data={chairsData.PUBLICATIONCHAIRS} />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Chairs;
