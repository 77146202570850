import React, { useEffect, useState } from 'react'
import HeaderBanner from '../ui/HeaderBanner'
import client from '../../client/Client'

const ContactUs = () => {
  const [location, setLocation] = useState("")
  useEffect(() => {
    
    client.fetch('*[_type == "SSDVersion"]')
    .then((result) => {
      setLocation(result[0]?.Location)
   
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  }, [])
  
  return (
    <div className='max-w-6xl mx-auto px-4 sm:px-6 pt-32 '>
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
          <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>
        <HeaderBanner title='Contact Us' />
<div className='flex space-x-10'>
    
<div className=' w-1/2 p-4 border-2 rounded-md'> 
            <h1 className='text-2xl font-semibold text-red-500'>
                Location: 
            </h1>
            <p>{location}</p>
        </div>
        <div className='p-4 w-1/2 border-2 rounded-md'> 
            <h1 className='text-2xl font-semibold text-red-500'>
                E-MAIL:
            </h1>
            <a href='mailto:info@ssd-conf.org' className='text-blue-500 hover:text-blue-600 '>
            info@ssd-conf.org
            </a>
        </div>
</div>

    </div>
  )
}

export default ContactUs