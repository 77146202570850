import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";

type CardProps = {
    index:number,
    _id: string,
    Place: string,
    Date: string,
    SSDEditionNumber: string,
    image: {
        asset: { _ref: string }
    },
    SSDVersionYear: string,
    SSDURL: string,
    Description: string,
}
export default function CustomCard(props: CardProps) {
    const navigate=useNavigate()
    
    return (
        <div className="relative h-full w-full rounded-md shadow-md  bg-blue-gray-300/10">
            <img
                src={`https://cdn.sanity.io/images/79vgbaej/production/${props.image.asset._ref.slice(6, -4) + '.' + props.image.asset._ref.slice(-3)}`}
                alt="img-blur-shadow "
                className="h-full w-full rounded-md shadow-md"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 p-24 font-inter">
                <h3 className="text-white font-bold text-4xl mb-4"> {props.SSDVersionYear}</h3>
                <div className="mb-4">
                    <h5 className="text-white font-bold text-3xl">  {props.SSDEditionNumber}</h5>
                    <div className="m-5 flex flex-row items-center justify-center gap-6">
                        {/* Date */}
                        <div className="flex flex-row items-center gap-2">
                            <svg className="h-[40px] w-[40px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#FFFFFF" strokeWidth="1.5" />
                                <path d="M12 8V12L14.5 14.5" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {/* <svg className="h-9 w-9" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z" stroke="#1C274C" strokeWidth="1.5" />
                                    <path opacity="0.5" d="M7 4V2.5" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                                    <path opacity="0.5" d="M17 4V2.5" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                                    <path opacity="0.5" d="M2 9H22" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M9 14.5L10.5 13V17" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M13 16V14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16Z" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                                </svg> */}
                            <p className="text-lg font-bold text-red-300">{props.Date}</p>
                        </div>
                        {/* Place */}
                        <div className="flex flex-row items-center  gap-2 ">
                            <svg className="h-[40px] w-[40px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M18 8L18.9487 8.31623C19.9387 8.64624 20.4337 8.81124 20.7169 9.20407C21 9.5969 21 10.1187 21 11.1623V16.829C21 18.1199 21 18.7653 20.6603 19.18C20.5449 19.3208 20.4048 19.4394 20.247 19.5301C19.7821 19.797 19.1455 19.6909 17.8721 19.4787C16.6157 19.2693 15.9875 19.1646 15.3648 19.2167C15.1463 19.235 14.9292 19.2676 14.715 19.3144C14.1046 19.4477 13.5299 19.735 12.3806 20.3097C10.8809 21.0596 10.131 21.4345 9.33284 21.5501C9.09242 21.5849 8.8498 21.6021 8.60688 21.6016C7.80035 21.6001 7.01186 21.3373 5.43488 20.8116L5.05132 20.6838C4.06129 20.3538 3.56627 20.1888 3.28314 19.7959C3 19.4031 3 18.8813 3 17.8377V12.908C3 11.2491 3 10.4197 3.48841 9.97358C3.57388 9.89552 3.66809 9.82762 3.76917 9.77122C4.34681 9.44894 5.13369 9.71123 6.70746 10.2358" stroke="#FFFFFF" strokeWidth="1.5" />
                                <path d="M6 7.70031C6 4.55211 8.68629 2 12 2C15.3137 2 18 4.55211 18 7.70031C18 10.8238 16.085 14.4687 13.0972 15.7721C12.4007 16.076 11.5993 16.076 10.9028 15.7721C7.91499 14.4687 6 10.8238 6 7.70031Z" stroke="#FFFFFF" strokeWidth="1.5" />
                                <circle cx="12" cy="8" r="2" stroke="#FFFFFF" strokeWidth="1.5" />
                            </svg>
                            <p className="text-lg font-bold text-red-300">{props.Place}</p>
                        </div>
                    </div>
                    {/* <p className="mb-2 font-bold text-red-400"> {props.date}</p> */}
                    <p className="mb-2 text-white/80 text-xl"> {props.Description}</p>
                </div>
                <div className="pt-0">
                    {
                        props.index !== 0 ? null :<a className="btn p-3 rounded-md text-black bg-white hover:bg-gray-100 w-full sm:w-auto sm:ml-4" href={`/learnmore/${props.SSDVersionYear}`}>Learn more</a>
                    }
                </div>
            </div>
        </div >
    )

}