

export default function Logo() {
  return (
    <a href="/" className="block" aria-label="Cruip">
      <img
        src="/images/logo-ssd.png"
        width={80}
        height={80}
        alt="SSD Logo"
      />
    </a>
  )
}
