import React, { useEffect, useState } from 'react'
import CardList from '../utils/cardlist'
import client from '../../client/Client'
import SCCardList from '../utils/SCCardList'
import HeaderBanner from '../ui/HeaderBanner'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

interface myDataType {
  CSP: Content[],
  PSE: Content[],
  SAC: Content[],
  SCI: Content[]
}
interface Content {
  Coutry: string,
  MemberName: string
}

const ScientificCommittee = () => {
const [open, setOpen] = useState(0);
  const [data, setData] = useState<myDataType>({
    CSP: [{
      Coutry: "",
      MemberName: ""
    }],
    SAC: [{
      Coutry: "",
      MemberName: ""
    }],
    SCI: [{
      Coutry: "",
      MemberName: ""
    }],
    PSE: [{
      Coutry: "",
      MemberName: ""
    }]
  })
  useEffect(() => {
    client.fetch('*[_type == "SCCommittees"]')
      .then((result) => {
        setData(result[0]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);
  
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title='Scientific Committee' />

     
      <Accordion open={ open === 1}>
                <AccordionHeader onClick={() => handleOpen(1)} >Scientific Committee CSP</AccordionHeader>
                <AccordionBody>
                  {/* <div className='space-y-6'>
                    {data.CSP && (
                      <div>
          
                        {
                          data.CSP.map((items, index) => {
                            return (
                              <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                {items.MemberName + ' ' +'(' + items.Coutry+')'} 
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    
                  </div> */}
                  <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
            data.SAC && data.SAC.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>

                </AccordionBody>
      </Accordion>
      <Accordion open={open === 2}>
                <AccordionHeader onClick={() => handleOpen(2)} >Scientific Committee PSE</AccordionHeader>
                <AccordionBody>
                  {/* <div className='space-y-6'>
                    {data.PSE && (
                      <div>
          
                        {
                          data.CSP.map((items, index) => {
                            return (
                              <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                {items.MemberName + ' ' +'(' + items.Coutry+')'} 
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    
                  </div> */}
                  <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
            data.PSE && data.PSE.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>
                </AccordionBody>
      </Accordion>
      <Accordion open={open === 3}>
                <AccordionHeader onClick={() => handleOpen(3)} >Scientific Committee SCI</AccordionHeader>
                <AccordionBody>
                  {/* <div className='space-y-6'>
                    {data.SCI && (
                      <div>
          
                        {
                          data.CSP.map((items, index) => {
                            return (
                              <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                {items.MemberName + ' ' +'(' + items.Coutry+')'} 
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    
                  </div> */}
                  <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
            data.SCI && data.SCI.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>
                </AccordionBody>
      </Accordion>
      <Accordion open={open === 4}>
                <AccordionHeader onClick={() => handleOpen(4)} >Scientific Committee SAC</AccordionHeader>
                <AccordionBody>
                  {/* <div className='space-y-6'>
                    {data.SAC && (
                      <div>
          
                        {
                          data.CSP.map((items, index) => {
                            return (
                              <p className='transition p-2 px-6 my-1 cursor-pointer hover:bg-red-200 hover:translate-x-2  hover:text-white w-1/2 rounded-md border shadow-md'>
                                {items.MemberName + ' ' +'(' + items.Coutry+')'} 
                              </p>
                            )
                          })
                        }
                      </div>
                    )
                    }
                    
                  </div> */}
                  <div className='grid gap-10' >
        <div className="flex flex-col">
  <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Member
              </th>
              <th scope="col" className="text-xl font-medium text-red-500 px-6 py-4 text-left">
              Country
              </th>
              
            </tr>
          </thead>
          <tbody>
           {
            data.SAC && data.SAC.map((item,index)=>{
              
              return <tr className={`border-b ${index % 2 === 0 ? "bg-gray-200" : ""}`}>

             
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.MemberName}
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {item.Coutry}
              </td>
            </tr>
            })
           }
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

       </div>
                </AccordionBody>
      </Accordion>
    
      
      </div >
  )
}

export default ScientificCommittee