import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../../client/Client";
import HeaderBanner from "../ui/HeaderBanner";
interface myData {
  CallForWorkshopsEmail:string,
  CallForWorkshops:{asset: {
    _ref: string;
  };}
}
const WorkshopsAndTutorial = () => {
  const [data, setData] = useState<myData>();
  const [date, setDate] = useState();
  useEffect(() => {
    client
      .fetch('*[_type == "Programs"]')
      .then((result) => {
        setData(result[0]);
        setDate(result[0].CallForWorkshopsDL);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 mb-32 ">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <HeaderBanner title="Workshop & Tutorial" />

      <div className="p-4 text-left ">
        <h1
          className="text-3xl text-red-500  font-semibold leading-tighter tracking-tighter mb-12"
          data-aos="zoom-out"
        >
          Call for Workshop & Tutorial Sessions
        </h1>

        <h1
          className="text-xl text-red-500 my-4 font-semibold leading-tighter tracking-tighter"
          data-aos="zoom-out"
        >
          SSD Call for Special Sessions
        </h1>
        {data?.CallForWorkshopsEmail &&  <p className="my-4">
          SSD encourages scientists/industrials from the world over who are
          working on the conference’s topics to organize workshops and/or
          tutorials. The workshop/tutorial should focus mainly on the latest
          trends in electrical engineering and information technology.
          Scientific and Industrial Workshop proposals must be submitted using
          the{" "}
          <a
            className="text-blue-500"
            href={`https://cdn.sanity.io/files/79vgbaej/production/${
              data?.CallForWorkshops?.asset._ref.slice(5, -4) + "." + data?.CallForWorkshops?.asset._ref.slice(-3)
            }`}
          >
            Template
          </a>{" "}
          and sent to the workshop, tutorial, and special session chairs via
          e-mail to{" "}
          <a className="text-blue-600" href={`mailto:${data?.CallForWorkshopsEmail}`}>
            {" "}
            {data?.CallForWorkshopsEmail}
          </a>
        </p>}
        <h1
          className="text-md text-gray-800 my-4 font-semibold leading-tighter tracking-tighter"
          data-aos="zoom-out"
        >
          Important date
        </h1>
        <p className="my-3 text-md">
          Deadline for submission of workshop/tutorial proposals:{" "}
          <span className="text-red-500"> {date}</span>
        </p>
      </div>
    </div>
  );
};

export default WorkshopsAndTutorial;
